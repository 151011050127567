import { FC, useState, FormEvent, ChangeEvent, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import HCaptcha from "@hcaptcha/react-hcaptcha";

import { ContactForm, ContactApi } from '../api/contact';
import "./contact.css"; 

const Contact: FC = () => {
  const { t } = useTranslation();

  const fields: ContactForm = {
    name: "",
    email: "",
    subject: "",
    message: "",
    hcaptcha: "",
  };

  // development key of hcaptcha
  // const siteKeyTest = "10000000-ffff-ffff-ffff-000000000001";

  // production key of hcaptcha
  const siteKey = "f1b2f95d-34b9-46ed-af79-13ae38314fb6";

  const [formData, setFormData] = useState(fields);
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState<'success' | 'error' | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [hToken, setHToken] = useState("");

  const captchaRef = useRef<HCaptcha>(null);

  const resetForm = () => {
    setValidated(false);
    setFormData(fields);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleVerificationSuccess = (token: string, ekey: string) => {
    setHToken(token);
  };

  // Update Form Data with hCaptcha token
  useEffect(() => {
    setFormData(prev => ({ ...prev, hcaptcha: hToken }));
  }, [hToken])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    
    let form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setLoading(true);
      setMessage(null);

      // API Call with form message.
      let result = await ContactApi.post(formData);
      
      if (result) {
        setMessage('success');
      }
      else {
        setMessage('error');
      }
      setShowModal(true);
      setLoading(false);
      resetForm();
      if (captchaRef) {
        captchaRef.current?.resetCaptcha();
      }
    }
  };

  const handleClose = () => setShowModal(false);

  return (
    <>
      <Header />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("title.alert")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t(`text.contact.${message !== null ? message : 'error'}`)}</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            {t("button.close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <main className="px-4 pb-4">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center py-5 text-accent fw-bold">
                {t("text.pageTitle.contact")}
              </h1>
            </Col>
          </Row>

          <Form
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
            className="mb-5"
          >
            <Row className="justify-content-center">
              <Form.Group
                as={Col}
                xs="12"
                md="5"
                xl="3"
                className="mb-3"
                controlId="name"
              >
                <Form.Label>{t("text.contact.name")}</Form.Label>
                <Form.Control
                  required
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  disabled={isLoading}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  {t("text.contact.name")} {t("text.contact.suffixError")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                xs="12"
                md="5"
                xl="3"
                className="mb-3"
                controlId="email"
              >
                <Form.Label>{t("text.contact.email")}</Form.Label>
                <Form.Control
                  required
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                  disabled={isLoading}
                  type="email"
                />
                <Form.Control.Feedback type="invalid">
                  {t("text.contact.email")} {t("text.contact.suffixError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="justify-content-center">
              <Form.Group
                as={Col}
                xs="12"
                md="10"
                xl="6"
                className="mb-3"
                controlId="subject"
              >
                <Form.Label>{t("text.contact.subject")}</Form.Label>
                <Form.Control
                  required
                  value={formData.subject}
                  name="subject"
                  onChange={handleChange}
                  disabled={isLoading}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  {t("text.contact.subject")} {t("text.contact.suffixError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="justify-content-center">
              <Form.Group
                as={Col}
                xs="12"
                md="10"
                xl="6"
                className="mb-3"
                controlId="message"
              >
                <Form.Label>{t("text.contact.message")}</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={7}
                  value={formData.message}
                  name="message"
                  onChange={handleChange}
                  disabled={isLoading}
                  type="textarea"
                />
                <Form.Control.Feedback type="invalid">
                  {t("text.contact.message")} {t("text.contact.suffixError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="justify-content-center mb-4">
              <Col
                xs="12"
                md="10"
                xl="6"
                className="justify-content-end d-flex"
              >
                <HCaptcha
                  sitekey={siteKey}
                  onVerify={(token, ekey) =>
                    handleVerificationSuccess(token, ekey)
                  }
                  ref={captchaRef}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                xs="12"
                md="10"
                xl="6"
                className="justify-content-end d-flex"
              >
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  disabled={isLoading || hToken === ""}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {t("text.contact.sending")}
                    </>
                  ) : (
                    t("text.contact.send")
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </main>
      <Footer />
    </>
  );
};
export default Contact;
