import { FC, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

import { GraphConfig } from '../api/graphs';
import { PlatformFeature } from '../api/platform';
import { formatNumber, formatLabel } from '../helpers/formatUtilities';

type MinimapProps = {
    config: GraphConfig;
    feature: PlatformFeature;
    setShowLegend: React.Dispatch<React.SetStateAction<boolean>>;
};



const MinimapLegend: FC<MinimapProps> = ({config, feature, setShowLegend}: MinimapProps) => {

    const { t } = useTranslation();

    const map = useMap();

    /**
     * Creates line of colors & value labels.
     */
    const getCuts = (config: GraphConfig): string => {
        if (!config.colormap?.colors[0]) return '';
        let cuts = '';
        for (let i = 0, n = config.colormap!.colors.length; i < n; i++) {
            cuts += (`
                <div class="cut">
                    <i  class="color"
                        style="background-color: ${config.colormap!.colors[i]};"
                    ></i>
                    <p>
                    ${formatNumber(config.colormap!.cuts[i])} - ${formatNumber(config.colormap!.cuts[i+1])}</p>
                </div>
            `);
        }
        cuts = '<div id="colorcuts">'+cuts+'</div>';
        return cuts;
    };

    const legendHTML = getCuts(config) !== '' ? L.DomUtil.create('aside', 'minimap-legend') : L.DomUtil.create('div');
    if (legendHTML.classList.contains('minimap-legend')) {
        legendHTML.innerHTML = `
            <div>
                <h4>${t(`variables.${formatLabel(feature.label)}`)}</h4>
                <p>${t('info.measuredIn')} ${feature.unit}</p>
                ${getCuts(config)}
            </div>
        `;
        legendHTML.addEventListener('click', () => setShowLegend(false));
    }

    /**
     * Custom leaflet control for rendering the legend component.
     */
    const LegendControlClass = L.Control.extend({
        onAdd: (map: L.Map) => {
            return legendHTML;
        },
        onRemove: (map: L.Map) => {},
    });
    
    useEffect(() => {
        // Place the legend custom control.
        const LegendControl = new LegendControlClass({ position: 'bottomright' });
        LegendControl.addTo(map);

        // Avoid duplicates.
        return () => {
            LegendControl.remove();
        };
    }, [LegendControlClass, map]);
    return null;
};

export default MinimapLegend;