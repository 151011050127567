import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { MapContainer, AttributionControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { PLATFORM_TYPES } from '../constants';
import { PlatformApi, PlatformResponse, Platform } from '../api/platform';
import Legends from './legends';
import { LoadingPlaceholder } from './placeholders';
import MapLayersControl from './map-layers-control';
import { addCruiserRoutes } from '../helpers/cruisersHelpers';

const Map: FC = () => {
  
  const { data: platformResponse, error } = useQuery<PlatformResponse, string>('mapData', PlatformApi.fetch, {refetchOnReconnect: false});
  if (error) console.error(error);

  // Group sensors by platform type.
  const groupedSensors: Platform[][] = useMemo(() => {
    const sensors = platformResponse?.data || [];
    const grouped: Platform[][] = [];
    
    if (sensors && Array.isArray(sensors)) {
      // Gets cruiser routes as an additional sensor.
      const updatedSensors = addCruiserRoutes(sensors);
      for (let type in PLATFORM_TYPES) {
        grouped.push(updatedSensors.filter(sensor => sensor.name === PLATFORM_TYPES[type]));
      }
    }
    return grouped;
  }, [platformResponse?.data])

  return (
    <MapContainer
      id='map'
      maxBounds={[[-80, -170], [80, 170]]}
      center={[-54, -70]}
      zoom={6}
      scrollWheelZoom={true}
      minZoom={3.2}
      maxZoom={12}
      attributionControl={false}
    >
      {
        !platformResponse ? <LoadingPlaceholder /> :
        <>
          <AttributionControl position="bottomright" prefix={false} />

          <MapLayersControl groupedSensors={groupedSensors}/>

          <Legends/>
        </>
      }
    </MapContainer>
  )
};

export default Map;