import { FC } from "react";
import Header from "../components/header";
import { useTranslation } from "react-i18next";

import { Container, Row, Col, Accordion } from "react-bootstrap";

import Footer from "../components/footer";
import "./faq.css";

const FAQ: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <main>
        <Container className="mb-5">
          <Row>
            <Col>
              <h1 className="text-center py-5 text-accent fw-bold">
                {t("text.pageTitle.faq")}
              </h1>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {t("text.faq.title.thanks")}
                  </Accordion.Header>
                  <Accordion.Body className="py-5">
                    {t("text.faq.text.thanks")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {t("text.faq.title.selectPlatforms")}
                  </Accordion.Header>
                  <Accordion.Body className="py-5">
                    {t("text.faq.text.selectPlatforms")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    {t("text.faq.title.selectStation")}
                  </Accordion.Header>
                  <Accordion.Body className="py-5">
                    {t("text.faq.text.selectStation")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {t("text.faq.title.selectCruiser")}
                  </Accordion.Header>
                  <Accordion.Body className="py-5">
                    {t("text.faq.text.selectCruiser")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    {t("text.faq.title.downloadData")}
                  </Accordion.Header>
                  <Accordion.Body className="py-5">
                    {t("text.faq.text.downloadData")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    {t("text.faq.title.downloadGraphs")}
                  </Accordion.Header>
                  <Accordion.Body className="py-5">
                    {t("text.faq.text.downloadGraphs")}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default FAQ;
