import { FC } from 'react';
import { Circle } from 'react-leaflet';

import { Platform } from '../api/platform';

interface MinimapMarkerProps extends Platform {
  showPopup?: boolean;
  color?: string;
}

const MinimapMarker: FC<MinimapMarkerProps> = ({sensor, name, location, features, showPopup=true, color}: MinimapMarkerProps) => {
    let pathOptions = {
        fill: true,
        color: color,
        fillColor: color
    }
    if (Array.isArray(location)) {
    return <Circle radius={1000} center={location[0]} pathOptions={pathOptions} />
        
    }
    else {
    return <Circle radius={1000} center={[location.latitude, location.longitude]} pathOptions={pathOptions} />
    }
};

export default MinimapMarker;