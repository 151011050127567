import { Auth, API as AwsAPI } from 'aws-amplify'

import { Filters, Request, Response } from './common';

export interface DownloadFilters extends Filters {
  start_date: string;
  end_date: string;
}

export interface DownloadRequest extends Request<DownloadFilters> {
  filters?: DownloadFilters;
}

export interface DownloadResponse extends Response<DownloadRequest, null, string> {
  metadata: {
    endpoint: string;
    query: DownloadRequest;
    config: null;
  };
  data: string;
}

export const DownloadApi = {
  get: async (request: DownloadRequest): Promise<DownloadResponse> => 
      {
        return await AwsAPI.post('api', 'data', {
        body: request,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }
      }).then(data => data).catch(error => console.error(error));
    }
}
