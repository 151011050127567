import { FC } from 'react';

import Map from '../components/map';
import Header from '../components/header';
import WelcomeModal from '../components/welcome-modal';
import './main.css'

const Main: FC = () => {

  return (
    <>
      <Header />
      <main className="fix-height">
        <WelcomeModal />
        <Map />
      </main>
    </>
  )
}
export default Main;
