import { FC, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { GraphFilters } from "../api/graphs";
import { PlatformFeature } from "../api/platform";
import type { TimeRanges } from "../helpers/getSensorListData";

type FiltersFormProps = {
	selectedFeature: string;
	feature: PlatformFeature;
	x_axis: string;
	disabled?: boolean;
	setFilters: (values: GraphFilters) => void;
	timeRanges?: TimeRanges;
};

const FiltersForm: FC<FiltersFormProps> = ({
	selectedFeature,
	feature,
	setFilters,
	disabled = false,
	timeRanges,
}: FiltersFormProps) => {
	const { t } = useTranslation();

	const day = 24 * 3600 * 1000;
	let dateRange: number = 0;

	if (timeRanges && feature) {
		dateRange =
			new Date(timeRanges[selectedFeature].end_date).getTime() -
			new Date(timeRanges[selectedFeature].start_date).getTime();
	} else if (feature) {
		dateRange =
			new Date(feature.time.end_date).getTime() -
			new Date(feature.time.start_date).getTime();
	}

	// Set Filters Values
	const [initialValues, setInitialValues] = useState<GraphFilters>();

	useEffect(() => {
		const values: GraphFilters = {
			start_date: timeRanges
				? DateTime.fromISO(
						timeRanges[selectedFeature].start_date.replace("Z", "")
				  ).toJSDate()
				: DateTime.fromISO(
						feature.time.start_date.replace("Z", "")
				  ).toJSDate(),
			end_date: timeRanges
				? DateTime.fromISO(
						timeRanges[selectedFeature].end_date.replace("Z", "")
				  ).toJSDate()
				: DateTime.fromISO(
						feature.time.end_date.replace("Z", "")
				  ).toJSDate(),
			quality: 0,
			start_depth_m: feature.depths ? feature.depths[0] : 0,
			end_depth_m: feature.depths
				? feature.depths[feature.depths.length - 1]
				: 0,
		};
		setInitialValues(values);
		setFilters(values);
	}, [setFilters, feature, selectedFeature, timeRanges]);

	const handleSubmit = (values: GraphFilters) => {
		setFilters(values);
	};

	if (!initialValues) return null;

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			onSubmit={handleSubmit}
		>
			{({
				values,
				handleChange,
				handleBlur,
				submitForm,
				setFieldValue,
			}) => (
				<Form>
					<section className='mb-3'>
						<label className='form-label'>
							{t("graphComponent.depthRange")}
						</label>
						{feature.depths && feature.depths.length > 1 ? (
							<div className='row'>
								<div className='col'>
									<select
										className='form-select'
										name='start_depth_m'
										disabled={disabled}
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.start_depth_m}
									>
										{feature.depths.map((value, index) => (
											<option key={index} value={value}>
												{value}
											</option>
										))}
									</select>
								</div>
								<div className='col'>
									<select
										className='form-select'
										name='end_depth_m'
										disabled={disabled}
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.end_depth_m}
									>
										{feature.depths.map((value, index) => (
											<option key={index} value={value}>
												{value}
											</option>
										))}
									</select>
								</div>
							</div>
						) : (
							<div className='row'>
								<div className='col'>
									<input
										type='text'
										placeholder={t("other.surface")}
										className='form-control'
										disabled
									/>
								</div>
							</div>
						)}
					</section>
					<section className='mb-3'>
						<label className='form-label'>
							{t("graphComponent.dateRange")}
						</label>
						{dateRange >= day ? (
							<div className='row'>
								<div className='col'>
									<ReactDatePicker
										className='form-control'
										name='start_date'
										selected={values.start_date}
										selectsStart={true}
										minDate={initialValues.start_date}
										maxDate={values.end_date}
										startDate={values.start_date}
										endDate={values.end_date}
										dateFormat='yyyy-MM-dd'
										onChange={(value) => {
											setFieldValue("start_date", value);
										}}
									/>
								</div>
								<div className='col'>
									<ReactDatePicker
										className='form-control'
										name='end_date'
										selected={values.end_date}
										selectsEnd={true}
										startDate={values.start_date}
										minDate={values.start_date}
										maxDate={initialValues.end_date}
										endDate={values.end_date}
										dateFormat='yyyy-MM-dd'
										onChange={(value) => {
											setFieldValue("end_date", value);
										}}
									/>
								</div>
							</div>
						) : (
							<div className='row'>
								<div className='col'>
									<ReactDatePicker
										className='form-control'
										name='end_date'
										selected={values.end_date}
										selectsEnd={true}
										startDate={values.start_date}
										minDate={values.start_date}
										maxDate={initialValues.end_date}
										endDate={values.end_date}
										dateFormat='yyyy-MM-dd'
										onChange={(value) => {
											setFieldValue("start_date", value);
										}}
										disabled
									/>
								</div>
							</div>
						)}
					</section>
					<button
						type='submit'
						className='btn btn-primary'
						onClick={() => submitForm().then()}
					>
						{t("button.render")}
					</button>
				</Form>
			)}
		</Formik>
	);
};

export default FiltersForm;
