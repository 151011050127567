import { FC, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { SignUpAttribute } from '@aws-amplify/ui';
import { Authenticator, Button, TextField, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { faFileDownload, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, Field } from 'formik';
import { useMutation } from 'react-query';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { GraphFilters, GraphRequest } from '../api/graphs';
import { DownloadFilters, DownloadRequest, DownloadResponse, DownloadApi } from '../api/download';
import '../views/details.css';


export type Option = {
  label: string;
  value: string;
}

type DownloadSectionProps = {
  graphRequest: GraphRequest;
  availableYAxes: Option[];
};


export const DownloadSection: FC<DownloadSectionProps> = ({
  graphRequest: {platform, sensors, y_axis, x_axis, filters},
  availableYAxes
}) => {
  
  const { t } = useTranslation();
  // Format filters dates
  const formatFilterDates = (filters: GraphFilters | undefined): DownloadFilters => {
    if (!filters) return {} as DownloadFilters;
    const { start_date, end_date } = filters;
    const formatedStart = DateTime.fromJSDate(start_date).toISODate();
    const formatedEnd = DateTime.fromJSDate(end_date).toISODate();
    return {...filters, start_date: formatedStart, end_date: formatedEnd};
  };

  // Set Download Filters and Parameters
  const [ filtersOn, setFiltersOn ] = useState<boolean>(true);
  const [ request, setRequest ] = useState<DownloadRequest>({
    platform,
    sensors,
    y_axis,
    x_axis,
    filters: filtersOn ? formatFilterDates(filters) : {} as DownloadFilters,
  });

  useEffect(() => {
    setRequest({
        platform,
        sensors,
        y_axis,
        x_axis,
        filters: filtersOn ? formatFilterDates(filters) : {} as DownloadFilters,
    });
  }, [platform, sensors, y_axis, x_axis, filters, filtersOn])
    

  // Fetch and Download data from AWS S3
  const {
    data: signedLink,
    error,
    mutate,
    isLoading: isDownloading,
  } = useMutation<DownloadResponse, Error, DownloadRequest>(DownloadApi.get, { mutationKey: 'downloadData' })
  
  useEffect(() => {
    if (signedLink) saveAs(signedLink.data);
    else if (error) console.error(error);
  }, [signedLink, error]);


  // AWS Cognito Authentication
  const services = {
    async handleSignUp(formData: any) {
      let { username, password, attributes } = formData;
      return Auth.signUp({
        username,
        password,
        attributes,
      });
    },
    async validateCustomSignUp(formData: any, touchedData: any) {
      if (!formData["custom:entity"]) {
        return {
          "custom:entity": 'You must type the name of your entity',
        };
      }
    },

  };

  
  return (
    <Authenticator
      className="auth"
      loginMechanisms={['email']}
      services={services}
      initialState="signUp"
      signUpAttributes={["email", "custom:entity"] as SignUpAttribute[]}
      components={{
        SignUp: {
          FormFields() {
            const { validationErrors, } = useAuthenticator();
            return (
              <>
                <Authenticator.SignUp.FormFields />
                <TextField
                  errorMessage={validationErrors["custom:Entity"] && validationErrors["custom:Entity"][0]}
                  hasError={validationErrors["custom:Entity"] ? true : false}
                  label="Entity"
                  required
                  id="custom:entity"
                  placeholder="Entity"
                  name="custom:entity"
                  type="text"
                ></TextField>
              </>
            );
          },
        },
    }}>
    {({ signOut, user }) => (
      <>
        {user ?
          <>
            <div className="row text-muted pt-3">
              <small className="col my-auto"><FontAwesomeIcon fixedWidth={true} icon={faUser} style={{marginRight:"5px"}} />{t('cognito.loggedAs')}: {user.getUsername()}</small>
              <Button className="col m-3" onClick={signOut}>{t('logout')}<FontAwesomeIcon fixedWidth={true} icon={faSignOutAlt} style={{marginLeft:"10px"}} /></Button>
            </div>
            <hr />
            <Formik 
              initialValues={{
                dataOption: filtersOn ? t('filtered') : t('all'),
                y_axes: [availableYAxes.find(axis => axis.value === y_axis)?.value],
              }}
              onSubmit={(values) => {
                mutate({
                  ...request,
                  y_axis: values.y_axes as string[]
                });
              }}
            >
              <Form className="d-flex align-middle">
                <div className="justify-content-around flex-column my-4 my-md-0 mx-auto w-100">
                  <div className="d-flex justify-content-around flex-column my-4 my-md-0 mx-auto w-100">
                    <label className="form-check-label my-auto" >
                      <Field type="radio" className="form-check-input" name="dataOption" value="all" onChange={() => setFiltersOn(false)}/>
                      <span className="ms-2">{t('button.all')}</span>
                    </label>
                    <label className="form-check-label my-auto" >
                      <Field type="radio" className="form-check-input" name="dataOption" value="filtered" onChange={() => setFiltersOn(true)}/>
                      <span className="ms-2">{t('button.filtered')}</span>
                    </label>
                    <small className="text-muted">
                      {filtersOn ? t('button.downloadFiltered') : t('button.downloadAll')}
                    </small>
                  </div>
                  <div className="d-flex flex-column my-4">
                    <label className="form-label d-block my-2 h6" htmlFor="y_axes">{t('button.selectedVars')}: </label>
                    <div className="checkbox-list d-flex flex-column">
                      {
                        availableYAxes.map(({label, value}) => (
                          <label key={label} className="d-inline-block">
                            <Field type="checkbox" className="form-check-input" name="y_axes" id="y_axes" value={value}/>
                            <span> {t(`variables.${label}`)}</span>
                          </label>
                        ))
                      }
                    </div>
                  </div>
                  <Button type="submit" variation="primary" disabled={isDownloading || false} className="my-2 my-md-0">
                    {isDownloading ? `${t('button.downloading')}` : `${t('button.downloadDataSets')}`}
                    <FontAwesomeIcon className="ms-2" fixedWidth={true} icon={faFileDownload} />
                  </Button>
                </div>
              </Form>
            </Formik>
            
          </>
        : null}
      </>
    )}
  </Authenticator>
  )
};