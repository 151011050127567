import { PlatformFeature } from './platform';
import { Filters, Request, Response } from './common';
import API from './api';

export interface GraphFilters extends Filters {
    start_date: Date;
    end_date: Date;
}

export interface GraphRequest extends Request<GraphFilters> {
    filters?: GraphFilters;
}

export interface GraphResponse extends Response<GraphRequest, GraphConfig, GraphPoint[]> {
    metadata: {
        endpoint: string;
        query: GraphRequest;
        config: GraphConfig;
    };
    data: GraphPoint[];
}

export interface GraphPoint {
    datetime: string;
    depth_m: number | null;
    value: number;
    location: 
    { latitude: number, longitude: number } | null;
    colormap: string;
}

export interface GraphConfig {
    colormap?: {
        colors: string[];
        cuts: number[];
    };
}

export interface GraphProps extends GraphRequest {
    feature: PlatformFeature
}


export const GraphApi = {
  get: async (request: GraphRequest): Promise<GraphResponse> => await API.post('graphs', { json: request }).json<GraphResponse>(),
}