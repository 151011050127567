import { Platform } from '../api/platform';


type Years = {
    [year: number]: boolean;
};

export type YearCategories = {
    [year: string]: Platform[];
};

type YearMonthCategories = {
    [year: string]: {
        [month: string]: Platform[];
    };
}

/**
 * Used in cruiser formatting.
 */
export const getByYears = (sensors: Platform[]): YearCategories => {
    const years: Years = {};
    
    sensors.forEach(sensor => {
        let date = sensor?.features[Object.keys(sensors[0].features)[0]]?.time?.start_date;
        let currentYear = date ? new Date(date).getFullYear() : -1 ;
        if (!years[currentYear] && currentYear !== -1) years[currentYear] = true;
    });

    const categories: YearCategories = {};
    Object.keys(years).forEach(year => {
        categories[year]= sensors.filter(sensor => {
            return new Date(sensor?.features[Object.keys(sensors[0].features)[0]]?.time?.start_date).getFullYear() === Number(year)
        });
    });
    
    return categories;
};



/**
 * Used in ferrybox formatting
 */
export const getByYearsMonths = (sensors: Platform[]): YearMonthCategories => {

    const categories: YearMonthCategories = {};
    sensors.forEach(sensor => {
        let date = sensor?.features[Object.keys(sensors[0].features)[0]]?.time?.start_date;
        let currentYear = date ? new Date(date).getFullYear() : -1;
        let currentMonth = date ? new Date(date).getMonth() : -1;
        if (
            categories[currentYear] === undefined &&
            currentYear !== -1 &&
            currentMonth !== -1
        ) {
            categories[currentYear] = {};
            categories[currentYear][currentMonth] = [sensor];
        }
        else if (
            categories[currentYear][currentMonth] === undefined &&
            currentYear !== -1 &&
            currentMonth !== -1
        ) {
            categories[currentYear][currentMonth] = [sensor];
        }
        else if (
            categories[currentYear][currentMonth] &&
            categories[currentYear][currentMonth].indexOf(sensor) ===  -1 &&
            currentYear !== -1 &&
            currentMonth !== -1
        ) categories[currentYear][currentMonth].push(sensor);
    });
    
    return categories;
};