import { FC, useState, useEffect, useRef, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMutation } from 'react-query';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { GraphApi, GraphRequest, GraphFilters, GraphPoint, GraphProps, GraphResponse } from '../api/graphs';
import { PlatformFeature } from '../api/platform';
import { significantFloat } from '../helpers/formatUtilities';
import { ErrorPlaceholder, LoadingPlaceholder, NoDataPlaceholder } from './placeholders';
import '../views/details.css';

require('highcharts/modules/stock')(Highcharts);
// Accesibility modules:
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);


const SensorFeature: FC<GraphProps> = ({platform, sensors, y_axis, x_axis, filters, feature}: GraphProps) => {
    const { t } = useTranslation();
  
    const  chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [ loadingGraph, setLoadingGraph ] = useState(true);
    
    // Update filters with proper datetime types
    const [ updatedFilters, setUpdatedFilters ] = useState(
        !filters ? {} : {
        ...filters,
        start_date: DateTime.fromJSDate(filters?.start_date).toISODate(),
        end_date: DateTime.fromJSDate(filters?.end_date).toISODate()
    });

    useEffect(() => {
        setUpdatedFilters(
            !filters ? {} : {
                ...filters,
                start_date: DateTime.fromJSDate(filters?.start_date).toISODate(),
                end_date: DateTime.fromJSDate(filters?.end_date).toISODate(),
            }
        );
    }, [filters]);

    // Get request data
    const [ request, setRequest ] = useState<GraphRequest>({
        platform,
        sensors,
        y_axis,
        x_axis,
        filters: updatedFilters as GraphFilters
    });
    
    useEffect(() => {
        setRequest({
            platform,
            sensors,
            y_axis,
            x_axis,
            filters: updatedFilters as GraphFilters
        });
    }, [platform, sensors, y_axis, x_axis, filters, updatedFilters])


    // Update Graph Data
    const {
        data: graphResponse,
        error,
        mutate,
        isLoading,
    } = useMutation<GraphResponse, Error, GraphRequest>(GraphApi.get, { mutationKey: 'getGraphData'})

    useEffect(() => {
        mutate(request);
    }, [mutate, request]);
    

    // Groups data by datetime ranges
    const datesBy: (
        filters: GraphFilters | undefined,
        ) => 'hour' | 'day' | 'month' | 'year' = (filters) => {
        let groupBy: 'hour' | 'day' | 'month' | 'year';
        if (!filters) return 'day';
        const dateRange = filters.end_date.getTime() - filters.start_date.getTime();
        const day = 24*3600*1000;
        const month = 31*day;

        if (dateRange === 0) {
            groupBy = 'day';
        }
        else if (dateRange <= day) {
            groupBy = 'hour';
        }
        else if (dateRange <= month) {
            groupBy = 'day';
        }
        else if (dateRange <= 24*month) {
            groupBy = 'month';
        }
        else {
            groupBy = 'year';
        }
        return groupBy;
    }

    // Creates series array when measuring variables by depth.
    const dateSeries: (
        by: 'hour' | 'day' | 'month' | 'year',
        data: GraphPoint[] | undefined
    ) => Highcharts.SeriesOptionsType[] = (by = 'day', data) => {
        
        if (!data) return [];

        const series: Highcharts.SeriesOptionsType[] = [];

        interface dateGroups {
            [key:string]: {
                collectedData: number[][]   // [[x,y,count], [...], ...]
            }
        }
        
        const groups: dateGroups = {};
        let date: string;
        let format: Intl.DateTimeFormatOptions;
        switch(by) {
            case 'hour':
                format = DateTime.DATETIME_MED;
                break;
            case 'month':
                format = {...DateTime.DATE_MED, day: undefined}
                break;
            case 'year':
                format = {...DateTime.DATE_MED, day: undefined, month: undefined}
                break;
            case 'day':
            default:
                format = DateTime.DATE_MED;
                break;
        }
        const uniqueDates: string[] = [];
        for (let sample of data) {
            date = sample.datetime ? DateTime.fromISO(sample.datetime.replace('Z', '')).toLocaleString(format) : 'No Date';
            if (uniqueDates.indexOf(date) === -1) uniqueDates.push(date);
        }
        uniqueDates.forEach(date => groups[date] = {collectedData: []});
        let added: boolean;
        data.forEach(sample => {
            date = sample.datetime ? DateTime.fromISO(sample.datetime.replace('Z','')).toLocaleString(format) : 'No Date';
            // Same x axis with average of every corresponding y value
            added = false;
            for (let axesValues of groups[date].collectedData) {
                if (sample.depth_m === axesValues[0]) {
                    axesValues[1] += sample.value ? sample.value : 0;
                    axesValues[2]++;
                    added = true;
                    break;
                }
            }
            if (!added && (sample.depth_m && sample.value)) {
                groups[date].collectedData.push([sample.depth_m, significantFloat(sample.value), 1]);
            }
        })
        
        for (let group in groups) {
            series.push({type: 'line', name: group, data: groups[group].collectedData.map(values => {
                return [
                    values[0],
                    significantFloat(values[1]/values[2])
                ]
            })});
        }
        return series;
    }
    
    // Creates series array when measuring variables by datetime.
    const depthSeries: (
        feature: PlatformFeature | null,
        data: GraphPoint[] | undefined
        ) => Highcharts.SeriesOptionsType[] = (feature, data) => {
            
        if (!data) return [];
        const series: Highcharts.SeriesOptionsType[] = [];
        let depthSerie: Highcharts.SeriesOptionsType;
        if (feature?.depths && feature?.depths.length >= 1) {
            for (let depth of feature?.depths) {
                depthSerie = {type: 'line', name: `${depth} m`, data: []};
                for (let sample of data) {
                    if (sample.depth_m === depth && depthSerie.data) {
                        depthSerie.data.push([
                            sample.datetime ? DateTime.fromISO(sample.datetime).toMillis() : null,
                            significantFloat(sample.value)
                        ]);
                    }
                }
                
                if (depthSerie.data && depthSerie.data.length !== 0) {
                    series.push(depthSerie)
                };
            }
        }
        else {
            series.push({
                type: 'line',
                name: 'Surface level',
                data: data.map(sample => [
                    sample.datetime ? DateTime.fromISO(sample.datetime).toMillis() : null,
                    significantFloat(sample.value)
                ])
            })
        }
        return series;
    }


    const chartOptions: Highcharts.ChartOptions = {
        type: 'linear',
        width: x_axis === 'depth_m' ? 450 : null,
        //height: x_axis === 'depth_m' ? '100%' : '',
        inverted: x_axis === 'depth_m' ? true : false,
        events: {
            load: () => {
                setLoadingGraph(false);
            },
        },
        style: {
            //height: 100,
            margin: 'auto',
        },
    };

    const tooltipOptions: Highcharts.TooltipOptions = {
        shared: true,
        formatter: function() {
            return this.points?.reduce((result, point) => {
                return result + `<br/><b style="color: ${point.color}">${point.series.name === 'Surface level' ? t('other.surface') : point.series.name}</b>: ${point.y ? significantFloat(point.y) : t('other.noValue')}`;
            }, `<b>${x_axis === 'depth_m' ? `${this.x} m` : `${DateTime.fromMillis(Number(this.x)).toUTC().toFormat('yyyy-LL-dd HH:mm')}`}</b>`)
        },
    };

    const yAxisOptions = {
        title: {
            text: feature?.label ? `${t(`variables.${feature?.label}`)} ( ${t(`other.${feature.unit}`)} )` : `${t(`variables.${y_axis}`)} ${feature?.unit ? `( ${t(`other.${feature.unit}`)} )` : ''}`,
        },
        labels: {
            format: `{text}`
        },
        opposite: x_axis === 'depth_m' ? true : false,
        lineWidth: 1,
    }

    const xAxisOptions = {
        title: {
            text: x_axis === 'datetime' ? t('graphComponent.datetime') : `${t('graphComponent.depth')} ${t('other.meters')}`,
        },
        labels: {
            format: `{text}`
        },
    }
    // Sets and memoizates initial series data, then updates it. 
    const seriesData = useMemo(() => x_axis === 'depth_m' ? dateSeries(datesBy(filters), graphResponse?.data) : depthSeries(feature, graphResponse?.data),
     [x_axis, graphResponse?.data, filters, feature]);


    if (isLoading) return <LoadingPlaceholder />;
    else if (error) return <ErrorPlaceholder />;
    else if (!graphResponse) return <NoDataPlaceholder />;


    if (!seriesData) return (<NoDataPlaceholder />);

    const options: Highcharts.Options = {
        title: {
            text: `${feature?.label ? t(`variables.${feature?.label}`) : t(`variables.${y_axis}`)} vs ${t(`graphComponent.${x_axis}`)}`
        },
        time:{
            useUTC: true,
        },
        chart: chartOptions,
        tooltip: tooltipOptions,
        yAxis: yAxisOptions,
        xAxis: xAxisOptions,
        plotOptions: {
            series: {
                turboThreshold: 0,
            }
        },
        legend: {
            enabled: true,
            labelFormatter() {
                return this.name === 'Surface level' ? t('other.surface') : this.name;
            },
        },
        accessibility: {
            enabled: true,
        },
        series: seriesData,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500,
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                    },
                    tooltip: {
                        enabled: true,
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -5
                        },
                        title: {
                            text: null
                        }
                    },
                    subtitle: {
                        text: ''
                    },
                    credits: {
                        enabled: false
                    },
                    chart: {
                        //height: x_axis === 'depth_m' ? 640 : 460,
                    }
                }
            }]
        }
    }
    

    return (
        <section className={"graph w-100 ps-xl-4 "+(x_axis === 'depth_m' ? 'is-large':'')}>
             {
                loadingGraph ? <LoadingPlaceholder /> : null
             }
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={x_axis === 'datetime' ? 'stockChart' : 'chart'}
                options={options}
                ref={chartComponentRef}
                containerProps={{ className: 'graph-container shadow-sm p-2' }}
            />
        </section>
    )
    

};

export default SensorFeature;
