import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Header from "../components/header";
import Footer from "../components/footer";
import Mooring from "../assets/mooring.jpg";
import Station from "../assets/station.jpg";
import Ferrybox from "../assets/ferrybox.jpg";
import Cruiser from "../assets/cruiser.jpg";
import "./details.css";

type SensorTranslated = {
  title: string;
  description: string;
  region: string;
  location: string;
  height?: string;
  depth?: string;
  verticalResolution?: string;
  frecuency?: string;
  information: string;
  list: [[string, string]];
};

const SensorDetails: FC = () => {
  const { platform, sensor } = useParams();
  const { t } = useTranslation();

  let img: string;
  let sensorLinks = t(`sensors.${sensor}.links`, {
    returnObjects: true,
  }) as Object;
  let sensorTranslated = t(`sensors.${sensor}`, {
    returnObjects: true,
    ...sensorLinks,
  }) as SensorTranslated;

  switch (platform) {
    case "mooring":
      img = Mooring;
      break;
    case "station":
      img = Station;
      break;
    case "ferrybox":
      img = Ferrybox;
      break;
    case "cruisers":
      img = Cruiser;
      break;
    default:
      img = "https://via.placeholder.com/800x400";
      break;
  }

  return (
    <>
      <Header />
      <div className="container my-md-4 py-sm-4 p-0">
        <section className="card shadow-lg text-bg-dark">
          <div className="card-img-container position-relative p-0">
            <img src={img} alt="" className="card-img" />
            <h1 className="sensor-title d-block w-100 ps-4 mb-0 text-light position-absolute bottom-0">
              {t(`special.${platform}`)}
              <br />
              {sensorTranslated.title}
            </h1>
          </div>
          <section className="card-body container mx-4">
            <div className="row my-3">
              <Link
                to="/sensors"
                className="d-inline w-auto text-decoration-none"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                {t(`button.return`)}
              </Link>
            </div>
            <div className="row mx-1">

              <div className="col-10 my-4 ">
                <p
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.description,
                  }}
                ></p>
              </div>

              <div className="col-10 my-4">
                <h5 className="card-title">{t(`special.region`)}:</h5>
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.region,
                  }}
                ></p>
              </div>

              <div className="col-10 my-4">
                <h5 className="card-title">{t(`special.coordinates`)}:</h5>
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.location,
                  }}
                ></p>
              </div>

              {sensorTranslated.verticalResolution && <div className="col-10 my-4">
                <h5 className="card-title">{t(`special.verticalResolution`)}:</h5>
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.verticalResolution|| '',
                  }}
                ></p>
              </div>}

              {sensorTranslated.height && <div className="col-10 my-4">
                <h5 className="card-title">{t(`special.height`)}:</h5>
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.height || '',
                  }}
                ></p>
              </div>}

              {sensorTranslated.depth && <div className="col-10 my-4">
                <h5 className="card-title">{t(`special.depth`)}:</h5>

                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.depth || '',
                  }}
                ></p>
              </div>}

              {sensorTranslated.frecuency && <div className="col-10 my-4">
                <h5 className="card-title">{t(`special.frecuency`)}:</h5>
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.frecuency || '',
                  }}
                ></p>
              </div>}

              <div className="col-10 my-4">
                <h5 className="card-title">{t(`special.information`)}:</h5>
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: sensorTranslated.information,
                  }}
                ></p>
              </div>
            </div>
            <div className="row mt-4 mx-1">
              <div className="col-10">
                <h5 className="card-title">{t(`special.variables`)}:</h5>
                <ul>
                  {sensorTranslated.list.map((item) => {
                    return (
                    item[1] ? 
                      <li className="card-text lh-lg">
                        <b
                        className="me-4"
                          dangerouslySetInnerHTML={{
                            __html: item[0] + ':',
                          }}
                        ></b>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item[1],
                          }}
                        >
                        </span>
                      </li> : <h3 className="sensor-subtitle-vars ">{item[0]}</h3>
                    );
                  })}
                </ul>
              </div>
            </div>
          </section>
          <div className="d-block my-4"></div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SensorDetails;
