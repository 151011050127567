import API from './api';

export interface ContactForm {
    name: string;
    email: string;
    subject: string;
    message: string;
    hcaptcha: string;
}

export const  ContactApi = {
    // Post message from Contact Us Form.
    post: async (form: ContactForm): Promise<boolean> => await API.post('contact-us', { json: form }).json<boolean>().catch(error => {
        console.error(error);
        return false;
    }),
}