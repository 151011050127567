import { PLATFORM_TYPES } from '../constants';
import { Platform } from '../api/platform';
import { YearCategories } from './getSensorsBy';


type CruiserRoutes = {
    [cruiserName: string]: Platform[];
};

type FormatedCruisers = {
  [year: string]: {
    route: Platform;
    stops: Platform[];
  };
}
  
// Adds extra cruiser routes as Polylines
export const addCruiserRoutes = (sensors: Platform[]): Platform[] => {
    const cruiserRoutes: CruiserRoutes = {};
    const routesList: Platform[] = [];

    const cruisers = sensors.filter(sensor => sensor.name === PLATFORM_TYPES.CRUISERS);

    // Create cruisers routes list
    let idName: string;
    cruisers.forEach(cruiser => {
      idName = cruiser.sensor.slice(0,cruiser.sensor.indexOf('__'));
      if (!cruiserRoutes[idName]) {
        cruiserRoutes[idName] = cruisers.filter(cruiser => 
          cruiser.sensor.slice(0, cruiser.sensor.indexOf('__')) === idName
        );
      }
    })

    let cruiserColor: string = "rgb(1, 76, 190)";
    let colorStep: number;
    
    for (let route in cruiserRoutes) {
      
      // Sort cruisers by station number
      cruiserRoutes[route].sort((a, b) => {
        
        let stationA = Number(a.sensor.split('__')[1]);
        let stationB = Number(b.sensor.split('__')[1]);
        
        if (stationA > stationB) return 1;
        else if (stationA < stationB) return -1;
        else return 0;
      });

      routesList.push({
        name: 'cruisers',
        sensor: `${route} Route`,
        location: cruiserRoutes[route].map(cruiser => Array.isArray(cruiser.location) ? cruiser.location[0] : [cruiser.location.latitude, cruiser.location.longitude]),
        features: cruiserRoutes[route][0].features,
        color: cruiserColor,
        relatedSensors: cruiserRoutes[route].map(cruiser => cruiser.sensor),
      })
      colorStep = Math.random()*94+1;
      cruiserColor = `rgb(${0+colorStep},${50+colorStep},${160+colorStep})`
    }
    return [...sensors, ...routesList];
  };


// Formats cruiser routes to get { route: Platform, stops: Platform[]}
export const formatCruisers = (yearCategories: YearCategories): FormatedCruisers => {
  const formatedCruisers: FormatedCruisers = {}
  Object.entries(yearCategories).forEach(year => {
    formatedCruisers[year[0]] = {
      route: year[1].filter(cruiser => Array.isArray(cruiser.location))[0],
      stops: year[1].filter(cruiser => !Array.isArray(cruiser.location)),
    };
  });
  
  return formatedCruisers;
};