import API from './api';

export interface Platform {
  name: string,
  sensor: string,
  location: 
  { latitude: number, longitude: number } | [number, number][],
  features: {
      [key: string]: PlatformFeature,
  },
  color?: string,
  relatedSensors?: string[],  // List with names of related sensors. Designed for cruiser routes.
}

export interface PlatformFeature {
  unit: string,
  description: string,
  dimension: string,
  medium: string,
  label: string,
  colormap: string,
  x_axis: string[],
  time: {
      start_date: string,
      end_date: string,
      time_resolution_in_hours: number
  }
  depths?: number[]
}

export interface PlatformResponse {
  metadata: {
      endpoint: string,
      query: {
          sensors: string | null
      }
  },
  data: Platform | Platform[]
}

export const PlatformApi = {
  // Get every sensor for map visualization
  fetch: async (): Promise<PlatformResponse> => await API.get('platforms').json<PlatformResponse>(),
  // Get single sensor for map visualization and graph filtering
  get: async (sensor: string, isList: boolean): Promise<PlatformResponse> => {
    return await API.get(`platforms/${sensor}${isList ? '?all=true' : ''}`).json<PlatformResponse>()
  },
}
