import type { Platform } from "../api/platform";

export type TimeRanges = {
	[feature: string]: { start_date: string; end_date: string };
};

export const getTimeRanges = (data: Platform): TimeRanges => {
	const timeRanges: TimeRanges = {};
	Object.entries(data.features).forEach(
		([feature, featData]) =>
			(timeRanges[feature] = {
				start_date: featData.time.start_date,
				end_date: featData.time.end_date,
			})
	);

	return timeRanges;
};
