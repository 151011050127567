import { FC } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCircleExclamation, faXmarkCircle, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import i18n from 'i18next';

type ErrorPlaceholderProps = {
    refetch?: () => Promise<unknown>;
    error?: any;
}
export const ErrorPlaceholder: FC<ErrorPlaceholderProps> = ({error, refetch}: ErrorPlaceholderProps) => {
    return (
        <div className="d-flex flex-column justify-content-center text-center w-50 h-25 h-md-50 m-auto custom-placeholder">
            <h2>Error</h2>
            <FontAwesomeIcon icon={faCircleExclamation} color='#333' size="3x" onClick={refetch}/>
            <code>{error}</code>
            <p>{i18n.t('placeholder.errorDescription')}</p>
        </div>
    );
};

export const LoadingPlaceholder: FC = () => {
    return (
        <div className="d-flex flex-column justify-content-center text-center w-50 h-25 h-md-50 m-auto my-md-4 custom-placeholder">
            <h2>{i18n.t('placeholder.loading')}</h2>
            <FontAwesomeIcon icon={faSpinner} className="loading-icon" color='#333' size="3x"/>
        </div>
    );
};

export const NoDataPlaceholder: FC = () => {
    return (
        <div className="d-flex flex-column justify-content-center text-center w-50 h-25 h-md-50 m-auto custom-placeholder">
            <h2>{i18n.t('placeholder.noData')}</h2>
            <FontAwesomeIcon icon={faXmarkCircle} color="#333" size="3x"/>
            <Link to={"/"} className="nav-link link-dark fs-5 m-4">
                {i18n.t('button.return')}
            </Link>
        </div>
    );
};

export const RotateDevicePlaceholder: FC = () => {
    return (
        <div className="rotate-device">
            <h3>{i18n.t('placeholder.rotate')}</h3>
            <FontAwesomeIcon icon={faArrowRotateRight} color="#FFF" size="3x" className="rotate-icon"/>
            <p><small>{i18n.t('placeholder.rotateDescription')}</small></p>
        </div>
    );
};