import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitterSquare,
  faLinkedin,
  faInstagram,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import StarmLogo from "../assets/starm.png";
import DOSquareLogo from "../assets/DO_cuadrado_blanco.png";
import ParqueEstrechoLogo from "../assets/parque_estrecho_alt.png";
import AustralBroomLogo from "../assets/austral_broom2.png";
import IdealLogo from "../assets/logo_ideal_white.png";
import UACHLogo from "../assets/UACH2.png";
import { useTranslation } from 'react-i18next';

import "./footer.css";

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-dark text-light ">
      <section className="row">


        {/* STARM LOGO */}
        <section className="col-12 col-lg-2">
          <img
            className="logo"
            id="starm-logo"
            src={StarmLogo}
            alt="starm logo"
          />
        </section>

        {/* INSTITUTIONS LOGOS */}
        <section className="col-12 col-lg-10">
          <div className="row">
            <div className="col-6 col-md-4">
              <h6>{t('footer.execute')}</h6>
              <hr />
              <div className="row justify-content-evenly">
                <a
                  className="col-12 col-sm-6 col-lg-6"
                  href="https://centroideal.cl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo"
                    id="ideal-logo"
                    src={IdealLogo}
                    alt="ideal logo"
                  />
                </a>
                <a
                  className="col-12 col-sm-6 col-lg-6"
                  href="https://uach.cl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo"
                    id="uach-logo"
                    src={UACHLogo}
                    alt="uach logo"
                  />
                </a>
              </div>
            </div>

            <div className="col-6 col-md-4">
              <h6>{t('footer.develop')}</h6>
              <hr />
              <div className="row justify-content-evenly">
                <a
                  className="col"
                  href="https://www.dataobservatory.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo"
                    id="do-logo"
                    src={DOSquareLogo}
                    alt="DO logo "
                  />
                </a>
              </div>
            </div>

            <div className="col-6 col-md-4  d-none d-md-inline-block">
              <h6>{t('footer.associate')}</h6>
              <hr />
              <div className="row justify-content-evenly">
                <a
                  className="col col-lg-6"
                  href="https://www.parquedelestrecho.cl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo"
                    id="parque-logo"
                    src={ParqueEstrechoLogo}
                    alt=""
                  />
                </a>
                <a
                  className="col col-lg-5"
                  href="http://www.tabsa.cl/portal/index.php/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo"
                    id="austral-logo"
                    src={AustralBroomLogo}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="row justify-content-center">
        {/* SOCIAL MEDIA */}
        <section className="col-12 col-md-8 col-lg-4 mt-4">
          <p>{t("footer.followUs")}</p>
          <div className="d-flex justify-content-evenly">
            <a
              href="https://web.facebook.com/centroideal.cl/?_rdc=1&_rdr"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faFacebookSquare as IconProp} size="2x" />
            </a>
            <a
              href="https://twitter.com/CentroIDEAL_CL"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faTwitterSquare as IconProp} size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/company/centroideal/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faLinkedin as IconProp} size="2x" />
            </a>
            <a
              href="https://www.instagram.com/centro.ideal/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faInstagram as IconProp} size="2x" />
            </a>
          </div>
        </section>
      </section>
    </footer>
  );
};

export default Footer;
