import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './i18n';
import './App.css';
import Main from './views/main';
import Detail from './views/details';
import Contact from './views/contact';
import Sensors from './views/sensors';
import SensorsDetails from './views/sensors-detail';
import About from './views/about';
import FAQ from './views/faq';
import NotFound from './views/not-found';


function App() {
  return (

    <BrowserRouter>
      <Routes>

        {/* Main Functionality */}
        <Route path='/' element={<Main />} />
        <Route path='sensor/:sensor' element={<Detail />} />
        <Route path='sensor/:sensor/:feature' element={<Detail />} />
        <Route path='sensor/:sensor/:feature/by/:axis' element={<Detail />} />
        
        {/* Other views on navigation */}
        <Route path='/sensors' element={<Sensors />} />
        <Route path='/sensors/:platform/:sensor' element={<SensorsDetails />} />
        <Route path='/about' element={<About />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/contact' element={<Contact />} />

        {/* Fallback to 404 */}
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />

      </Routes>
    </BrowserRouter>

  );
}

export default App;
