import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../components/header";
import Footer from "../components/footer";
import "./about.css";
//logos
import logoStarm from "../assets/starm_color.png";
import logoIdeal from "../assets/ideal.png";
import logoDo from "../assets/do.png";

const About: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <main>
        <Container>
          <Row>
            <Col>
              <h1 className="text-center py-5 text-accent fw-bold">
                {t("text.pageTitle.about")}
              </h1>
            </Col>
          </Row>
          <Row className="border-bottom border-dark py-5 mx-1">
            <Col
              xs="12"
              lg="4"
              className="d-flex align-items-center justify-content-center justify-content-lg-start order-2 order-lg-1"
            >
              <img src={logoStarm} alt="STARM" className="about-logo" />
            </Col>
            <Col className="order-1 order-lg-2">
              <h4 className="py-1 fw-bold">STARM</h4>
              <p>
                <Trans
                  i18nKey={"text.about.starm"}
                  components={{
                    starmLink: <a href="https://starm.cl"></a>,
                  }}
                />
              </p>
            </Col>
          </Row>
          <Row className=" border-bottom border-dark py-5 mx-1">
            <Col
              xs="12"
              lg="4"
              className="d-flex align-items-center justify-content-center justify-content-lg-start order-2 order-lg-1"
            >
              <img src={logoIdeal} alt="IDEAL" className="about-logo" />
            </Col>
            <Col className="order-1 order-lg-2">
              <h4 className="py-1 fw-bold">IDEAL</h4>
              <p>
                <Trans
                  i18nKey={"text.about.ideal"}
                  components={{
                    idealLink: <a href="https://www.centroideal.cl/"></a>,
                    anidLink: <a href="https://www.anid.cl/"></a>,
                    fondapLink: (
                      <a href="https://www.anid.cl/centros-e-investigacion-asociativa/centros-fondap/"></a>
                    ),
                    uachLink: <a href="https://www.uach.cl/"></a>,
                    udecLink: <a href="http://udec.cl/"></a>,
                    awiLink: <a href="https://www.awi.de/en"></a>,
                    inachLink: <a href="http://www.inach.cl/inach/"></a>,
                  }}
                />
              </p>
            </Col>
          </Row>

          <Row className="py-5 mx-1">
            <Col
              xs="12"
              lg="4"
              className="d-flex align-items-center justify-content-center justify-content-lg-start order-2 order-lg-1"
            >
              <img src={logoDo} alt="DO" className="about-logo" />
            </Col>
            <Col className="order-1 order-lg-2">
              <h4 className="py-1 fw-bold">Data Observatory</h4>
              <p>
                <Trans
                  i18nKey={"text.about.do"}
                  components={{
                    doLink: <a href="https://www.dataobservatory.net/"></a>,
                    mincienciaLink: (
                      <a href="https://www.minciencia.gob.cl/"></a>
                    ),
                    mineconLink: <a href="https://www.economia.gob.cl/"></a>,
                    awsLink: <a href="https://aws.amazon.com/"></a>,
                    uaiLink: <a href="https://www.uai.cl/"></a>,
                  }}
                />
              </p>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default About;
