import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../components/header';
import Footer from '../components/footer';
import './details.css';

const NotFound: FC = () => {
    
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <main className="container  my-4 py-4">
                <div className="row flex-column text-accent text-center mx-auto">
                    <h2 className="d-block col my-4 ">{t('text.notFound.title')}</h2>
                    <h1 id="title-404" className="d-block col my-4 fw-bold">404</h1>
                    <p className="d-block col my-4  pb-4 fs-5">{t('text.notFound.message')}</p>
                </div>
            </main>
            <div className="d-block my-4 py-4"></div>
            <Footer />
        </>
    );
};

export default NotFound;
