
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            /*eslint-disable no-useless-computed-key*/
            en: {
                translation: {
                    info: {
                        measuredIn: 'In',
                        present: 'Present',
                    },
                    sensors: {
                        'canal-beagle': {
                            title: 'Beagle Channel',
                            description: 'At the end of May 2019, the weather station was installed in the north of Hoste Island in the Beagle Channel in front of Yendegaia Fjord, where the IDEAL Center deployed an oceanographic mooring. The weather station provides relevant information to study the ocean-atmosphere coupling in the Beagle Channel.',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: '54.95°S, 68.70°W',
                            height: '10 m',
                            frecuency: '1 hour',
                            information: 'From 2019-05-23-05 - 2021-08-15',
                            list: [
                                ['Air Temperature and Relative Humidity', 'Campbell Scientific HMP155A. More information {{temperatureInfo}}.'],
                                ['Wind speed and direction', 'Campbell Scientific 05103-45. More information {{windInfo}} .'],
                                ['Atmospheric pressure', 'Vaisala CS106. More information {{pressureInfo}}.'],
                                ['Solar radiation', 'Li-Cor LI-200R. More information {{radiationInfo}}.'],
                            ],
                            links:{
                              temperatureInfo: '<a href="https://www.campbellsci.com/hmp155a" target="_blank"> here</a>',
                              windInfo: '<a href="https://www.campbellsci.com/p05103-45-alpine-wind" target="_blank"> here</a>',
                              pressureInfo: '<a href="https://www.campbellsci.com/cs106" target="_blank"> here</a>',
                              radiationInfo: '<a href="https://www.licor.com/env/products/light/pyranometer" target="_blank"> here</a>',
                            }
                        },
                        'punta-santa-ana': {
                            title: 'Punta Santa Ana',
                            description: 'During 2017 a meteorological station was installed in Punta Santa Ana, in cooperation with the {{park}}, that will make it possible to establish the ocean-atmosphere coupling at the Magellan Strait and its impact on physical-chemical and biological processes.',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: '53.632933°S, 70.914719°W',
                            height: '7 m',
                            frecuency: '1 hour',
                            information: 'From 27-07-2017 - present.',
                            list: [
                                ['Air Temperature and Relative Humidity', 'Campbell Scientific HMP155A. More information {{temperatureInfo}}.'],
                                ['Wind speed and direction', 'Campbell Scientific 05103-45. More information {{windInfo}}.'],
                                ['Atmospheric pressure', 'Vaisala CS106. More information {{pressureInfo}}.'],
                                ['Solar radiation', 'Li-Cor LI-200R. More information {{radiationInfo}}.'],
                            ],
                            links:{
                              park: '<a href="https://www.parquedelestrecho.cl/" target="_blank">Parque del Estrecho de Magallanes</a>',
                              temperatureInfo: '<a href="https://www.campbellsci.com/hmp155a" target="_blank"> here</a>',
                              windInfo: '<a href="https://www.campbellsci.com/p05103-45-alpine-wind" target="_blank"> here</a>',
                              pressureInfo: '<a href="https://www.campbellsci.com/cs106" target="_blank"> here</a>',
                              radiationInfo: '<a href="https://www.licor.com/env/products/light/pyranometer" target="_blank"> here</a>',
                            }
                        },
                        yaghan: {
                            title: 'Yaghan',
                            description: 'First FerryBox equipment permanently installed on a ferry in Chile. The equipment began operation during 2022 on the Yaghan ferry ({{tabsa}}). This equipment was acquired thanks to funding from the National Research and Development Agency (ANID) within the framework of the FONDEQUIP program (project EQM190013). The equipment allows continuous measurements of physical-chemical and biological parameters between Punta Arenas and Puerto Williams. In addition, the FerryBox has a MaxxSampler water sampler that allows remote collection of surface water and an automated collector of lipophilic toxins produced by microalgae (developed by the {{awi}}). The team also has 3 spectro-radiometers installed on the ferry bridge to measure the optical properties of water (financed by the {{uol}}).',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: 'Punta Arenas-Puerto Williams',
                            depth: 'Surface',
                            frecuency: '5 minutes',
                            information: 'From 9/2022 - present.',
                            list: [
                                ['Temperature at entrance', 'SBE 38, dry mateable SBE 38 temperature probe. More information {{temperatureInInfo}}.'],
                                ['Temperature and salinity', 'Shipboard Thermosalinograph Seabird SBE45. More information {{temperatureOutInfo}}.'],
                                ['Oxygen water saturation and dissolved oxygen', 'Aanderaa Oxygen Optode 4835. More information {{saturationInfo}}.'],
                                ['Partial pressure of carbon dioxide', 'KM Contros HydroC CO2FT.4H-JENA engineering. More information {{pressureInfo}}.'],
                                ['pH', 'Autonomous Sunburst SAMI Flow-Thru, AFT-pH. More information {{phInfo}}.'],
                                ['Chlorophyll-a fluorescence', 'turbidity and Chromophoric dissolved organic matter (CDOM), Wetlabs Triplet with Chl-a, CDOM-Fluorescence and Turbidity/backscatter. More information {{fluorInfo}}.'],
                                ['Nitrate concentration', 'Seabird Submersible Ultraviolet Nitrate Analyzer SUNA V2. More information {{nitratoInfo}}.'],
                                ['Crude oil', 'Turner Cyclops7 sensor. More information {{oilInfo}}.'],
                                ['Photosynthetic Active Radiation (PAR)', 'Ramses VIS (Hyperspectral Irradiance Sensor). More information {{radiationInfo}}. '],
                            ],
                            links:{
                              tabsa: '<a href="http://www.tabsa.cl" target="_blank">Austral Broom shipping company</a>',
                              awi: '<a href="https://www.awi.de/" target="_blank"> Alfred Wegener Institut</a>',
                              uol: '<a href="https://uol.de/icbm" target="_blank">Institut für Chemie und Biologie des Meeres of the Universidad  of Oldenburg</a>',

                              temperatureInInfo: '<a href="https://www.seabird.com/sbe-38-digital-oceanographic-thermometer/product?id=60762467703" target="_blank"> here </a>',
                              temperatureOutInfo: '<a href="https://www.seabird.com/sbe45-microtsg-thermosalinograph/product?id=54627900541" target="_blank"> here </a>',
                              saturationInfo: '<a href="https://www.aanderaa.com/media/pdfs/d385_aanderaa_oxygen_sensor_4835.pdf" target="_blank"> here </a>',
                              pressureInfo: '<a href="https://www.4h-jena.de/en/maritime-technologies/sensors/hydrocrco2ft/" target="_blank"> here </a>',
                              phInfo: '<a href="http://www.sunburstsensors.com/products/oceanographic-ph-sensor-benchtop.html" target="_blank"> here </a>',
                              fluorInfo: '<a href="https://www.seabird.com/eco-triplet/product?id=60762467720" target="_blank"> here </a>',
                              nitratoInfo: '<a href="https://www.seabird.com/nutrient-sensors/suna-v2-nitrate-sensor/family?productCategoryId=54627869922" target="_blank"> here </a>',
                              oilInfo: '<a href="https://www.turnerdesigns.com/oil-fluorometer" target="_blank"> here </a>',
                              radiationInfo: '<a href="https://www.trios.de/en/ramses.html" target="_blank"> here </a>',
                          }
                        },
                        'mooring-beagle-channel': {
                            title: 'Beagle Channel (Yendegaia)',
                            description: 'Mooring deployed in the Beagle Channel at the outlet of the Yendegaia Fjord (Beagle Channel) in July 2017. The mooring has temperature, salinity and pressure sensors to monitor the high and low frequency oceanographic conditions of the Beagle Channel. It has sensors installed at 50, 100 and 200 m depth. At a depth of 200 m, a Technicap Model PPS 3/3 -24S sediment trap is installed (sampling frequency every 2 weeks). More information {{moreInfo}}.',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: '54.907°S, 68.664°W',
                            depth: '260m',
                            frecuency: '20 minutes',
                            information: 'From julio 2017-present.',
                            list: [
                                ['Temperature', 'Star-Oddi DST-CT. More information {{temperatureInfo}}.'],
                                ['Temperature and pressure', 'Star-Oddi DST-CTD. More information {{pressureInfo}}.'],
                                ['16/8/2021- present at 192 m temperature and salinity: CT RBRduo3', ' More information {{salinityInfo}}.']
                            ],
                            links:{
                              moreInfo: '<a href="https://www.technicap.com/products/sediment-trap" target="_blank"> here </a>',
                              temperatureInfo: '<a href="https://www.star-oddi.com/products/submersible-water-sensors/conductivity-logger-measuring-device" target="_blank"> here </a>',
                              pressureInfo: '<a href="https://www.star-oddi.com/products/submersible-water-sensors/salinity-logger-probe-CTD" target="_blank"> here </a>',
                              salinityInfo: '<a href="https://rbr-global.com/products/standard-loggers/rbrvirtuoso-rbrduo/" target="_blank"> here </a>',
                            }
                        },
                        cruisers:{
                            title: '',
                            description: 'Several oceanographic cruises were conducted by the IDEAL center along the Beagle Channel to characterize its general hydrography from the western end to the vicinity of the Yendegaia Fjord (more information {{moreInfo}}).',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: 'Beagle Channel',
                            depth: 'Surface to close to the bottom',
                            verticalResolution: '0,5m',
                            information: 'October 2016, June  2017, June 2018.',
                            list: [
                              ['CTD (2016)', null],
                              ['Conductivity, Temperature and pressure (depth)', 'SBE 19 Sealogger CTD. More information {{conductivity16}}'],
                              ['Dissolved oxygen', 'SBE 43. More information {{oxygen16}}'],
                              ['Fluorescence', 'WET Labs ECO-AFL/FL. More information {{fluor16}}'],
                              ['CTD (2017-present)', null],
                              ['Conductivity, Temperature and pressure (depth)', ' SBE 25plus Sealogger CTD. More information {{conductivity17 }}'],
                              ['Dissolved oxygen', 'SBE 43. More information {{oxygen17}}'],
                              ['Fluorescence', 'WET Labs ECO-FLNTU. More information {{fluor16}}'],
                              ['Photosynthetically Active Radiation (PAR)', 'SBE sensor. More information {{photo17}}'],
                              ['Turbidity', 'WET Labs ECO NTU. More information {turbidity17}}'],
                            ],
                            links:{
                              moreInfo       : '<a href="https://www.frontiersin.org/articles/10.3389/fmars.2021.621822/full" target="_blank">  here </a>',
                              conductivity16 : '<a href="https://www.seabird.com/asset-get.download.jsa?id=54795900501" target="_blank">  here </a>',
                              oxygen16       : '<a href="https://www.seabird.com/sbe-43-dissolved-oxygen-sensor/product?id=60762467728" target="_blank">  here </a>',
                              fluor16        : '<a href="https://www.seabird.com/fluorometers/eco-fluorometers/family-downloads?productCategoryId=54627869905" target="_blank">  here </a>',
                              conductivity17 : '<a href="https://www.seabird.com/sbe-25plus-sealogger-ctd/product?id=60429374753" target="_blank">  here </a>',
                              oxygen17       : '<a href="https://www.seabird.com/sbe-43-dissolved-oxygen-sensor/product?id=60762467728" target="_blank">  here </a>',
                              fluor17        : '<a href="https://www.seabird.com/fluorometers/eco-fluorometers/family-downloads?productCategoryId=54627869905" target="_blank">  here </a>',
                              photo17        : '<a href="https://www.seabird.com/photosynthetically-active-radiation-par-sensor/product?id=60762467732" target="_blank">  here </a>',
                              turbidity17    : '<a href="https://www.seabird.com/asset-get.download.jsa?id=56303490101" target="_blank">  here </a>',

                            }

                        }
                    },
                    special: {
                        sensor: 'Sensor',
                        stations_gen: 'Stations',
                        platform_one: 'Platform',
                        platform_other: 'Platforms',
                        ferrybox: 'Ferrybox',
                        ferrybox_other: 'Ferryboxes',
                        mooring: 'Mooring',
                        moorings: 'Moorings',
                        station: 'Meteorological Station',
                        stations: 'Meteo. Stations',
                        stations_lg: 'Meteorological Stations',
                        cruiser: 'Cruiser',
                        cruisers: 'Cruises',
                        route: 'Route',
                        region: 'Region',
                        coordinates: 'Coordinates',
                        height: 'Height',
                        depth: 'Depth',
                        verticalResolution: 'Vertical Resolution',
                        frecuency: 'Frecuency',
                        information: 'Information',
                        variables: 'Variables'
                    },
                    months: {
                        January: 'January',
                        February: 'February',
                        March: 'March',
                        April: 'April',
                        May: 'May',
                        June: 'June',
                        July: 'July',
                        August: 'August',
                        September: 'September',
                        October: 'October',
                        November: 'November',
                        December: 'December'
                    },
                    nav: {
                        home: 'Home',
                        sensors: 'Sensors',
                        about: 'About',
                        center: 'IDEAL Center',
                        faq: 'FAQ',
                        contact: 'Contact',
                        langES: 'Spanish',
                        langEN: 'English',
                    },
                    button: {
                        base: 'Base Layers',
                        open: 'Open',
                        render: 'Render',
                        all: 'All',
                        downloadAll: 'Download all data (filters are not applied).',
                        downloadFiltered: 'Download the filtered data as shown in the graph.',
                        filtered: 'Filtered',
                        selectedVars: 'Selected Variables',
                        downloadDataSets: 'Download Data Sets',
                        downloading: 'Downloading',
                        colorMap: 'Color Legend',
                        contact: 'Contact',
                        idealCenter: 'IDEAL Center',
                        return: 'Return',
                        downloadMap: 'Download Map',
                        close: "Close"
                    },
                    other: {
                        grey: 'Grey',
                        surface: 'Surface level',
                        meters: 'meters',
                        degrees: 'degrees',
                        ['°C']: '°C',
                        ['ppb']: 'ppb',
                        ['nm']: 'nm',
                        ['uM']: 'uM',
                        ['l/min']: 'l/min',
                        ['pH']: 'pH',
                        ['mg/l']: 'mg/l',
                        ['%']: '%',
                        ['µg/l']: 'µg/l',
                        ['µatm']: 'µatm',
                        ['W/m2']: 'W/m2',
                        ['NTU']: 'NTU',
                        ['psu']: 'psu',
                        ['m/s']: 'm/s',
                        ['mm']: 'mm',
                        ['m']: 'm',
                        ['ms']: 'ms',
                        ['mbar']: 'mbar',
                        ['mg/m3']: 'mg/m3',
                        noValue: 'No Value',
                    },
                    title: {
                        feature: 'Feature',
                        filters: 'Filters',
                        download: 'Download',
                        ideal: 'Oceanographic Data',
                        uach: 'UACh Magallanes Region Oceanographic Data',
                        developers: 'Powered by Data Observatory',
                        alert: "Message",
                    },
                    graphComponent: {
                        by: 'By',
                        depthRange: 'Depth Range',
                        dateRange: 'Date Range',
                        download: 'Download',
                        datetime: 'Datetime',
                        depth: 'Depth',
                        depth_m: 'Depth',
                    },
                    popup: {
                        availableVars: 'Available Variables',
                        belongs: 'Belongs to'
                    },
                    cognito: {
                        signIn: 'Sign In',
                        loggedAs: 'Logged in as',
                    },
                    placeholder: {
                        errorDescription: 'Please try again later.',
                        loading: 'Loading...',
                        noData: 'No data available.',
                        rotate: 'Rotate your device for a better experience.',
                        rotateDescription: '*Note that the minimap might not be available on smaller devices.'
                    },
                    variables: {
                        ['Solar radiation (400 - 700 nm)']: 'Solar radiation (400 - 700 nm)',
                        ['Solar radiation']: 'Solar radiation',
                        ['Relative humidity']: 'Relative humidity',
                        ['Temperature']: 'Temperature',
                        ['Burst in meters per second']: 'Burst in meters per second',
                        ['Burst']: 'Burst',
                        ['Flow in']: 'Flow in',
                        ['Salinity in psu']: 'Salinity in psu',
                        ['Salinity in']: 'Salinity',
                        ['Turbidity']: 'Turbidity',
                        ['O2 water saturation']: 'O2 water saturation',
                        ['O2 water']: 'O2 saturation',
                        ['Temperature in Celcius degrees']: 'Temperature in Celcius degrees',
                        ['Total Chl-a concentration']: 'Total Chl-a concentration',
                        ['Total Chl-a']: 'Total Chl-a',
                        ['Cumulative rainfall']: 'Cumulative rainfall',
                        ['Nitrate concentration']: 'Nitrate concentration',
                        ['Nitrate']: 'Nitrate',
                        ['Temperature (at entrance)']: 'Temperature (at entrance)',
                        ['Sound speed in m/s']: 'Sound speed in m/s',
                        ['Sound speed']: 'Sound speed',
                        ['Crude oil']: 'Crude oil',
                        ['CDOM (Chromophoric dissolved organic matter)']: 'CDOM (Chromophoric dissolved organic matter)',
                        ['CDOM']: 'CDOM',
                        ['Fluorescence']: 'Fluorescence',
                        ['Dissolved oxygen']: 'Dissolved oxygen',
                        ['pH']: 'pH',
                        ['Wind direction (standard deviation)']: 'Wind direction (standard deviation)',
                        ['Wind direction']: 'Wind direction',
                        ['Partial CO2 pressure']: 'Partial CO2 pressure',
                        ['Partial CO2']: 'Partial CO2',
                        ['Air pressure']: 'Air pressure',
                        ['Wind speed in meters per second']: 'Wind speed in meters per second',
                        ['Wind speed']: 'Wind speed',
                        ['Bottom depth at station']: 'Bottom depth at station',
                        ['Bottom depth']: 'Bottom depth',

                    },
                    text: {
                        pageTitle: {
                            about: 'About Us',
                            faq: 'FAQ',
                            sensors: 'Sensors',
                            contact: 'Contact Us',
                        },
                        about: {
                            starm: 'Environmental Data Time Series of the Magallanes and Chilean Antarctic Region (<starmLink>STARM</starmLink>), was developed as a technology transfer product of the research carried out by the IDEAL center.\n\nSTARM aims to make available to the national and international community the information provided by the platforms (moorings, cruises, meteorological stations and FerryBox) installed in different stations or vessels in the Magallanes Region and Antarctic peninsula. FerryBox is funded by the FONDEQUIP program (Fund for Scientific and Technological Equipment) project EQM 190013.',
                            ideal: 'The Research Centre Dynamics of High Latitude Marine Ecosystem (<idealLink>IDEAL</idealLink>)is funded by the Chilean National Agency for Research and Development (<anidLink>ANID</anidLink>), through its Fund for Research Centers in Priority Areas (<fondapLink>FONDAP</fondapLink>).\n\nIt is led by the Universidad Austral de Chile (<uachLink>UACh</uachLink>), with partner institutions the Universidad de Concepción (<udecLink>UdeC</udecLink>) and the Alfred Wegener Institute (<awiLink>AWI</awiLink>) of Germany. Logistical funding for Antarctic research is provided by the Instituto Antártico Chileno (<inachLink>INACH</inachLink>).',
                            do: 'Data Observatory (<doLink>DO</doLink>) is a nonprofit, public-private-academia collaboration led by the <mincienciaLink>Ministry of Science, Technology, Knowledge and Innovation</mincienciaLink> and the <mineconLink>Ministry of Economy</mineconLink>, created in alliance with Amazon Web Services (<awsLink>AWS</awsLink>) and Universidad Adolfo Ibáñez (<uaiLink>UAI</uaiLink>).\n\nDO has the mission to acquire, process, store, and provide availability for high-quality and large volume datasets in order to contribute to the development of astroinformatics and artificial intelligence initiatives that seek to promote knowledge, science and innovation. DO performs as one of the structural axis regarding the national policy on artificial intelligence.'
                        },
                        faq: {
                            title: {
                                thanks: 'How to acknowledge STARM?',
                                selectPlatforms: 'How to select specific platforms?',
                                selectStation: 'How to select a CTD station?',
                                selectCruiser: 'How to select an entire cruise?',
                                downloadData: 'How to download the data?',
                                downloadGraphs: 'How to save a plot or figure?',
                            },
                            text: {
                                thanks: 'We request that the STARM online platform be specifically and clearly acknowledged if data downloaded is used for analyses and visualizations in publications, posters, oral presentations, reports, Web pages, and other types of scientific media.\n\nCut-and-paste the following general STARM acknowledge statement into any of the forms of scientific communication listed above:\n\n“[Analyses and visualizations/Meteorological data/Cruise data/Mooring data/Ferrybox data] was provided by STARM online platform (https://www.starm.cl/) funded, developed, and maintained by the Agencia Nacional de Investigación y Desarrollo (ANID) through project FONDAP-IDEAL, grant number 15150003 and Data Observatory foundation. Dataset accessed [YYYY-MM-DD] at https://doi.org/¿????.”\n\nMeeting presentations: If STARM data visualizations are utilized in public presentations (particularly scientific meeting presentations), we would like to know. Such information is very important as justification for continuing funding and development and maintaining the platform running. We would greatly appreciate a short email message providing the title of the presentation, the abstract (if available), and the meeting where it was presented. Please send this message to XXXXXX@uach.cl with “STARM presentation information” as the Subject.',
                                selectPlatforms: 'In the upper right corner of the map you can select base layers (GEBCO, OpenStreetMap or OpenStreetmap (Grey) for visualization of different maps. You can also select each of the available platforms (Moorings, Ferrybox, Meteo. Stations, Cruises) which contain a submenu where you can select different dates and locations. Once you select the platform or date, the selection will be displayed on the map.\n\nYou also can select each of the platforms by clicking on the map (coded by colors, in the lower left corner )',
                                selectStation: 'CTD data are displayed in blue and dark blue color. Each station is represented by dots, where data of an individual station can be accessed by clicking on a specific dot.',
                                selectCruiser: 'To display the data of the entire cruise you can click over the line that connects the dots of a specific cruise.',
                                downloadData: 'To be able to download the data of a specific platform, you need to create an account and login. Data is downloaded as a .csv file (text files with information separated by commas).',
                                downloadGraphs: 'To be able to download a plot or figure, there is a contextual menuin the upper right corner, click on it and choose the format in which you want to download (png, jpeg, pdf or svg).',
                            }
                        },
                        contact: {
                            name: 'Name',
                            email: 'Email',
                            subject: 'Subject',
                            message: 'Message',
                            suffixError: " field is invalid.",
                            success: "Message sent succesfully.",
                            error: "Sorry, we couldn't deliver your message. Please retry later.",
                            sending: 'Sending...',
                            send: 'Send',
                        },
                        welcome: {
                            title: 'Welcome to STARM!',
                            body: 'Here you\'ll have access to the latest data registered by the STARM team:\nMeteorological data taken by ferryboxes, cruises, meteorological stations and moorings at the "Magallanes y la Antártica Chilena" Region.',
                            footer: 'STARM: "Series de Tiempo de datos Ambientales de la Región de Magallanes y la Antártica Chilena"'
                        },
                        notFound: {
                            title: 'Oh no!',
                            message: 'We don\'t have what you are looking for, but you can use the top navigation to return to the home page.',
                        },
                    },
                    logout: 'Log Out',
                    footer: {
                        execute: 'Executed by',
                        develop: 'Developed by',
                        associate: 'Associated Institutions',
                        followUs: 'Follow us in our social media',
                    },
                }
            },
            es: {
                translation: {
                    info: {
                        measuredIn: 'En',
                        present: 'Presente',
                    },
                    sensors: {
                        'canal-beagle': {
                            title: 'Canal Beagle',
                            description: 'A finales de mayo de 2019 se instaló la estación meteorológica en el norte de la isla Hoste en el Canal Beagle frente al fiordo Yendegaia, en donde el Centro IDEAL mantiene un anclaje oceanográfico. La estación meteorológica genera información relevante para estudiar el acoplamiento entre el océano y la atmósfera en el Canal Beagle.',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: '54.95°S, 68.70°O',
                            height: '10 m',
                            frecuency: '1 hora',
                            information: 'Desde 23-05-2019 - 15-08-2021',
                            list: [
                                ['Temperatura del aire y humedad relativa', 'Campbell Scientific HMP155A. Más información {{temperatureInfo}}.'],
                                ['Velocidad del viento y dirección', 'Campbell Scientific 05103-45. Más información {{windInfo}}.'],
                                ['Presión atmosférica', 'Vaisala CS106. Más información {{pressureInfo}}.'],
                                ['Radiación solar', 'Li-Cor LI-200R. Más información {{radiationInfo}}.'],
                            ],
                            links:{
                              temperatureInfo: '<a href="https://www.campbellsci.com/hmp155a" target="_blank"> aquí </a>',
                              windInfo: '<a href="https://www.campbellsci.com/p05103-45-alpine-wind" target="_blank"> aquí </a>',
                              pressureInfo: '<a href="https://www.campbellsci.com/cs106" target="_blank"> aquí </a>',
                              radiationInfo: '<a href="https://www.licor.com/env/products/light/pyranometer" target="_blank"> aquí </a>',
                            }
                        },
                        'punta-santa-ana': {
                            title: 'Punta Santa Ana',
                            description: 'El año 2017 se instaló la estación meteorológica en “Punta Santa Ana”, en cooperación con el {{park}}, que permitirá establecer el acoplamiento entre el océano y la atmósfera en el Estrecho de Magallanes y el impacto sobre los procesos físicos-químicos y biológicos.',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: '53.632933°S, 70.914719°O',
                            height: '7 m',
                            frecuency: '1 hora',
                            information: 'Desde 27-07-2017 - presente.',
                            list: [
                                ['Temperatura del aire y humedad relativa', 'Campbell Scientific HMP155A. Más información {{temperatureInfo}}. '],
                                ['Velocidad del viento y dirección', 'Campbell Scientific 05103-45. Más información {{windInfo}}. '],
                                ['Presión atmosférica', 'Vaisala CS106. Más información {{pressureInfo}}. '],
                                ['Radiación solar', 'Li-Cor LI-200R. Más información {{radiationInfo}}. '],
                            ],

                            links:{
                              park: '<a href="https://www.parquedelestrecho.cl/" target="_blank">Parque del Estrecho de Magallanes</a>',
                              temperatureInfo: '<a href="https://www.campbellsci.com/hmp155a" target="_blank"> aquí </a>',
                              windInfo: '<a href="https://www.campbellsci.com/p05103-45-alpine-wind" target="_blank"> aquí </a>',
                              pressureInfo: '<a href="https://www.campbellsci.com/cs106" target="_blank"> aquí </a>',
                              radiationInfo: '<a href="https://www.licor.com/env/products/light/pyranometer" target="_blank"> aquí </a>',
                            }

                        },
                        yaghan: {
                            title: 'Yaghan',
                            description: 'Primer equipo FerryBox instalado de manera permanente en un transbordador en Chile.  El equipo comenzó su operación durante el 2022 en la embarcación Yaghan de la {{tabsa}} naviera Austral Broom. Este equipo fue adquirido gracias al financiamiento de la Agencia Nacional de Investigación  y Desarrollo (ANID) en el marco del concurso FONDEQUIP, proyecto EQM190013.  El equipo permite mediciones de parámetros físico-químicos y biológicos de manera continua entre Punta Arenas y Puerto Williams. Además, el FerryBox cuenta con un muestreador de agua MaxxSampler que permite la colecta remota de agua superficial y un colector automatizado de toxinas lipofílicas producidas por microalgas (desarrollado  por el {{awi}}) . El equipo además cuenta con 3 espectro-radiómetros instalados en el puente del transbordador para medir las propiedades ópticas del agua (financiados por el {{uol}}).',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: 'Punta Arenas-Puerto Williams',
                            depth: 'Superficie',
                            frecuency: '5 minutos',
                            information: 'Desde 9/2022 - presente.',
                            list: [
                                ['Temperatura en la entrada', 'SBE 38, temperature probe. Más información {{temperatureInInfo}} .'],
                                ['Temperatura y salinidad', 'Shipboard Thermosalinograph Seabird SBE45. Más información {{temperatureOutInfo}}.'],
                                ['Saturación de agua y oxígeno disuelto', 'Aanderaa Oxygen Optode 4835. Más información {{saturationInfo}}.'],
                                ['Presión parcial de dióxido de carbono', 'KM Contros HydroC CO2FT.4H-JENA engineering. Más información {{pressureInfo}}.'],
                                ['pH', 'Autonomous Sunburst SAMI Flow-Thru, AFT-pH. Más información {{phInfo}}.'],
                                ['Fluorescencia de la clorofila-a', 'turbidez y materia orgánica disuelta cromofórica (CDOM), Wetlabs Triplet with Chl-a, CDOM-Fluorescence and Turbidity/backscatter. Más información {{fluorInfo}}.'],
                                ['Concentración de nitrato', 'Seabird Submersible Ultraviolet Nitrate Analyzer SUNA V2. Más información {{nitratoInfo}}.'],
                                ['Petróleo crudo', 'Turner Cyclops7 sensor. Más información {{oilInfo}}.'],
                                ['Radiación fotosintéticamente activa (PAR)', 'Ramses VIS (Hyperspectral Irradiance Sensor). Más información {{radiationInfo}} .'],
                            ],
                            links:{
                              tabsa: '<a href="http://www.tabsa.cl" target="_blank"> naviera Austral Broom</a>',
                              awi: '<a href="https://www.awi.de/" target="_blank"> Alfred Wegener Institut</a>',
                              uol: '<a href="https://uol.de/icbm" target="_blank">Institut für Chemie und Biologie des Meeres de la Universidad de Oldenburg</a>',

                              temperatureInInfo: '<a href="https://www.seabird.com/sbe-38-digital-oceanographic-thermometer/product?id=60762467703" target="_blank"> aquí </a>',
                              temperatureOutInfo: '<a href="https://www.seabird.com/sbe45-microtsg-thermosalinograph/product?id=54627900541" target="_blank"> aquí </a>',
                              saturationInfo: '<a href="https://www.aanderaa.com/media/pdfs/d385_aanderaa_oxygen_sensor_4835.pdf" target="_blank"> aquí </a>',
                              pressureInfo: '<a href="https://www.4h-jena.de/en/maritime-technologies/sensors/hydrocrco2ft/" target="_blank"> aquí </a>',
                              phInfo: '<a href="http://www.sunburstsensors.com/products/oceanographic-ph-sensor-benchtop.html" target="_blank"> aquí </a>',
                              fluorInfo: '<a href="https://www.seabird.com/eco-triplet/product?id=60762467720" target="_blank"> aquí </a>',
                              nitratoInfo: '<a href="https://www.seabird.com/nutrient-sensors/suna-v2-nitrate-sensor/family?productCategoryId=54627869922" target="_blank"> aquí </a>',
                              oilInfo: '<a href="https://www.turnerdesigns.com/oil-fluorometer" target="_blank"> aquí </a>',
                              radiationInfo: '<a href="https://www.trios.de/en/ramses.html" target="_blank"> aquí </a>',
                            }
                        },
                        'mooring-beagle-channel': {
                            title: 'Canal Beagle (Yendegaia)',
                            description: 'Anclaje instalado en Canal Beagle en la salida del fiordo Yendegaia (Canal Beagle) en julio de 2017. El anclaje cuenta con sensores de temperatura, salinidad y presión para monitorear las condiciones oceanográficas de alta y baja frecuencia del Canal Beagle. Posee sensores instalados a 50, 100 y 200 m de profundidad. A los 200 m de profundidad se encuentra instalada una trampa de sedimento Technicap Modelo PPS 3/3 -24S (frecuencia de muestreo cada 2 semanas) más información {{moreInfo}}.',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: '54.907°S,  68.664°O',
                            depth: '260m',
                            frecuency: '20 minutos',
                            information: 'Desde julio 2017-presente.',
                            list: [
                                ['Temperatura', 'Star-Oddi DST-CT. Más información {{temperatureInfo}}.'],
                                ['Temperatura y presión', ' Star-Oddi DST-CTD. Más información {{pressureInfo}}.'],
                                ['16/8/2021- presente a 192 m temperatura y salinidad: CT RBRduo3', ' Más información {{salinityInfo}}.']
                            ],

                            links:{
                              moreInfo: '<a href="https://www.technicap.com/products/sediment-trap" target="_blank">  aquí </a>',
                              temperatureInfo: '<a href="https://www.star-oddi.com/products/submersible-water-sensors/conductivity-logger-measuring-device" target="_blank">  aquí </a>',
                              pressureInfo: '<a href="https://www.star-oddi.com/products/submersible-water-sensors/salinity-logger-probe-CTD" target="_blank">  aquí </a>',
                              salinityInfo: '<a href="https://rbr-global.com/products/standard-loggers/rbrvirtuoso-rbrduo/" target="_blank">  aquí </a>',
                            }
                        },
                        cruisers:{
                            title: '',
                            description: 'Varios cruceros oceanográficos fueron realizados por el centro IDEAL a lo largo del Canal Beagle para caracterizar su hidrografía general desde el extremo oeste hasta las cercanías del fiordo Yendegaia (más información {{moreInfo}}).',
                            region: 'Magallanes y de la Antártica Chilena',
                            location: 'Canal Beagle',
                            depth: 'Superficie a cercanías del fondo',
                            verticalResolution: '0,5m',
                            information: 'Octubre 2016, junio 2017 2018, junio 2018.',
                            list: [
                              ['CTD (2016)', null],

                              ['Conductividad', 'Temperatura y presión (profundidad), SBE 19 Sealogger CTD. Más información {{conductivity16}}'],
                              ['Oxígeno disuelto', 'SBE 43. Más información {{oxygen16}}'],
                              ['Fluorescencia', 'WET Labs ECO-AFL/FL. Más información {{fluor16}}.'],
                              ['CTD (2017-presente)', null],
                              ['Conductividad', 'Temperatura y presión (profundidad), SBE 25plus Sealogger CTD. Más información {{conductivity17}}'],
                              ['Oxígeno disuelto', 'SBE 43. Más información {{oxygen17}}'],
                              ['Fluorescencia', 'WET Labs ECO-AFL/FL. Más información {{fluor17}}'],
                              ['Radiación fotosintéticamente activa (PAR)', 'SBE sensor. Más información {{photo17}}'],
                              ['Turbidez', 'WET Labs ECO NTU. Más información {{turbidity17}}'],
                            ],
                            links:{
                              moreInfo       : '<a href="https://www.frontiersin.org/articles/10.3389/fmars.2021.621822/full" target="_blank">  aquí </a>',
                              conductivity16 : '<a href="https://www.seabird.com/asset-get.download.jsa?id=54795900501" target="_blank">  aquí </a>',
                              oxygen16       : '<a href="https://www.seabird.com/sbe-43-dissolved-oxygen-sensor/product?id=60762467728" target="_blank">  aquí </a>',
                              fluor16        : '<a href="https://www.seabird.com/fluorometers/eco-fluorometers/family-downloads?productCategoryId=54627869905" target="_blank">  aquí </a>',
                              conductivity17 : '<a href="https://www.seabird.com/sbe-25plus-sealogger-ctd/product?id=60429374753" target="_blank">  aquí </a>',
                              oxygen17       : '<a href="https://www.seabird.com/sbe-43-dissolved-oxygen-sensor/product?id=60762467728" target="_blank">  aquí </a>',
                              fluor17        : '<a href="https://www.seabird.com/fluorometers/eco-fluorometers/family-downloads?productCategoryId=54627869905" target="_blank">  aquí </a>',
                              photo17        : '<a href="https://www.seabird.com/photosynthetically-active-radiation-par-sensor/product?id=60762467732" target="_blank">  aquí </a>',
                              turbidity17    : '<a href="https://www.seabird.com/asset-get.download.jsa?id=56303490101" target="_blank">  aquí </a>',

                            }
                        }
                    },
                    special: {
                        sensor: 'Sensor',
                        stations_gen: 'Estaciones',
                        platform_one: 'Plataforma',
                        platform_other: 'Plataformas',
                        ferrybox: 'Ferrybox',
                        ferrybox_other: 'Ferryboxes',
                        mooring: 'Anclaje',
                        moorings: 'Anclajes',
                        station: 'Estación Meteorológica',
                        stations: 'Estaciones Meteo.',
                        stations_lg: 'Estaciones Meteorológicas',
                        cruiser: 'Crucero',
                        cruisers: 'Cruceros',
                        route: 'Ruta',
                        region: 'Región',
                        coordinates: 'Coordenadas',
                        verticalResolution: 'Resolución Vertical ',
                        height: 'Altitud',
                        depth: 'Profundidad',
                        frecuency: 'Frecuencia',
                        information: 'Información',
                        variables: 'Variables'
                    },
                    months: {
                        January: 'Enero',
                        February: 'Febrero',
                        March: 'Marzo',
                        April: 'Abril',
                        May: 'Mayo',
                        June: 'Junio',
                        July: 'Julio',
                        August: 'Agosto',
                        September: 'Septiembre',
                        October: 'Octubre',
                        November: 'Noviembre',
                        December: 'Diciembre'
                    },
                    nav: {
                        home: 'Inicio',
                        sensors: 'Sensores',
                        about: 'Acerca',
                        center: 'Centro IDEAL',
                        faq: 'FAQ',
                        contact: 'Contacto',
                        langES: 'Español',
                        langEN: 'Inglés',
                    },
                    button: {
                        base: 'Capas Base',
                        open: 'Abrir',
                        render: 'Graficar',
                        all: 'Todo',
                        downloadAll: 'Descarga todos los datos (los filtros no son aplicados).',
                        downloadFiltered: 'Descarga los datos filtrados tal como los muestra el gráfico.',
                        filtered: 'Filtrados',
                        selectedVars: 'Variables Seleccionadas',
                        downloadDataSets: 'Descargar Datos',
                        downloading: 'Descargando',
                        colorMap: 'Leyenda de Colores',
                        contact: 'Contacto',
                        idealCenter: 'Centro IDEAL',
                        return: 'Volver',
                        downloadMap: 'Descargar Mapa',
                        close: "Cerrar"
                    },
                    other: {
                        grey: 'Gris',
                        surface: 'Nivel de superficie',
                        meters: 'metros',
                        degrees: 'grados',
                        ['°C']: '°C',
                        ['ppb']: 'ppb',
                        noValue: 'Sin Valor'
                    },
                    title: {
                        feature: 'Variable',
                        filters: 'Filtros',
                        download: 'Descargar',
                        ideal: 'Datos Oceanográficos',
                        uach: 'Datos Oceanográficos UACh, Región de Magallanes',
                        developers: 'Desarrollado por Data Observatory',
                        alert: "Mensaje",
                    },
                    graphComponent: {
                        by: 'Por',
                        depthRange: 'Rango de Profundidad',
                        dateRange: 'Rango de Fechas',
                        download: 'Descargar',
                        datetime: 'Fecha y Hora',
                        depth: 'Profundidad',
                        depth_m: 'Profundidad',
                        meters: 'metros',
                    },
                    popup: {
                        availableVars: 'Variables Disponibles',
                        belongs: 'Pertenece a'
                    },
                    cognito: {
                        signIn: 'Ingresar',
                        loggedAs: 'Ha ingresado como',
                    },
                    placeholder: {
                        errorDescription: 'Por favor inténtelo más tarde.',
                        loading: 'Cargando...',
                        noData: 'No hay datos disponibles.',
                        rotate: 'Gire su dispositivo para una mejor experiencia.',
                        rotateDescription: '*Note que el minimapa puede no estar disponible en dispositivos más pequeños.'
                    },
                    variables: {
                        ['Solar radiation (400 - 700 nm)']: 'Radiación solar (400 - 700 nm)',
                        ['Solar radiation']: 'Radiación solar',
                        ['Relative humidity']: 'Humedad relativa',
                        ['Temperature']: 'Temperatura',
                        ['Burst in meters per second']: 'Ráfaga en metros por segundo',
                        ['Burst']: 'Ráfaga',
                        ['Flow in']: 'Flujo de entrada',
                        ['Salinity in psu']: 'Salinidad en psu',
                        ['Salinity in']: 'Salinidad',
                        ['Turbidity']: 'Turbidez',
                        ['O2 water saturation']: 'Saturación de O2 en agua',
                        ['O2 water']: 'Saturación O2',
                        ['Temperature in Celcius degrees']: 'Temperatura en grados Celcius',
                        ['Total Chl-a concentration']: 'Concentración total Chl-a',
                        ['Total Chl-a']: 'Chl-a total',
                        ['Cumulative rainfall']: 'Lluvia acumulada',
                        ['Nitrate concentration']: 'Concentración de nitrato',
                        ['Nitrate']: 'Nitrato',
                        ['Temperature (at entrance)']: 'Temperatura (de entrada)',
                        ['Sound speed in m/s']: 'Velocidad del sonido en m/s',
                        ['Sound speed']: 'Velocidad del sonido',
                        ['Crude oil']: 'Petróleo crudo',
                        ['CDOM (Chromophoric dissolved organic matter)']: 'CDOM (Chromophoric dissolved organic matter)',
                        ['CDOM']: 'CDOM',
                        ['Fluorescence']: 'Fluorescencia',
                        ['Dissolved oxygen']: 'Oxígeno disuelto',
                        ['pH']: 'pH',
                        ['Wind direction (standard deviation)']: 'Dirección del viento (desviación estándar)',
                        ['Wind direction']: 'Dirección del viento',
                        ['Partial CO2 pressure']: 'Presión parcial de CO2',
                        ['Partial CO2']: 'CO2 parcial',
                        ['Air pressure']: 'Presión del aire',
                        ['Wind speed in meters per second']: 'Velocidad del viento en metros por segundo',
                        ['Wind speed']: 'Velocidad del viento',
                        ['Bottom depth at station']: 'Profundidad al fondo en la estación',
                        ['Bottom depth']: 'Profundidad al fondo',
                    },
                    text: {
                        pageTitle: {
                            about: 'Acerca de Nosotros',
                            faq: 'Preguntas Frecuentes',
                            sensors: 'Sensores',
                            contact: 'Contáctanos',
                        },
                        about: {
                            starm: 'Series de Tiempo de Datos Ambientales de la Región de Magallanes y península Antártica (<starmLink>STARM</starmLink>), nace como un producto de transferencia tecnológica de la investigación realizada por el centro IDEAL.\n\nSTARM tiene como objetivo poner a disposición de la comunidad nacional e internacional la información proporcionada por las plataformas (anclajes, cruceros, estaciones meteorológicas y FerryBox) instalados en diferentes puntos o embarcaciones en la Región de Magallanes y la península Antártica. FerryBox es financiado por el programa FONDEQUIP (Fondo de Equipamiento Científico y Tecnológico) proyecto EQM 190013.',
                            ideal: 'El Centro de Investigación Dinámica de Ecosistemas Marinos de Altas Latitudes (<idealLink>IDEAL</idealLink>) es financiado por la Agencia Nacional de Investigación y Desarrollo (<anidLink>ANID</anidLink>), a través de su programa Fondo de Financiamiento de Centros de Investigación en Áreas Prioritarias (<fondapLink>FONDAP</fondapLink>).\n\nEs liderado por la Universidad Austral de Chile (<uachLink>UACh</uachLink>), siendo sus instituciones asociadas la Universidad de Concepción (<udecLink>UdeC</udecLink>) y el Alfred Wegener Institute (<awiLink>AWI</awiLink>) de Alemania. El financiamiento logístico para la investigación antártica es proveído por el Instituto Antártico Chileno (<inachLink>INACH</inachLink>).',
                            do: 'Data Observatory (<doLink>DO</doLink>) es una colaboración público-privada-academia, sin fines de lucro, liderada por el <mincienciaLink>Ministerio de Ciencia, Tecnología, Conocimiento e Innovación</mincienciaLink> y el <mineconLink>Ministerio de Economía, Fomento y Turismo</mineconLink>, creada junto con Amazon Web Services (<awsLink>AWS</awsLink>) y la Universidad Adolfo Ibáñez (<uaiLink>UAI</uaiLink>).\n\nEl DO tiene la misión de adquirir, almacenar, procesar, analizar y disponibilizar conjuntos de datos de gran volumen y calidad, para contribuir al desarrollo de conocimiento, ciencia, tecnología e innovación, gracias a la ciencia de datos y la inteligencia artificial, en ámbitos como la astronomía, el cambio climático, la actividad sísmica, la disponibilidad de recursos hídricos y otras actividades o fuentes relevantes para el país.'
                        },
                        faq: {
                            title: {
                                thanks: '¿Cómo agradecer a STARM?',
                                selectPlatforms: '¿Cómo seleccionar plataformas específicas?',
                                selectStation: '¿Cómo seleccionar una estación de CTD?',
                                selectCruiser: '¿Cómo seleccionar un crucero completo?',
                                downloadData: '¿Cómo descargar datos?',
                                downloadGraphs: '¿Cómo descargar un gráfico o figura?',
                            },
                            text: {
                                thanks: 'Solicitamos que la plataforma en línea STARM sea reconocida de manera específica y clara si los datos descargados se utilizan para análisis y visualización en publicaciones, posters, presentaciones orales, informes, páginas web y otros tipos de medios científicos.\n\nCorte y pegue la siguiente declaración de agradecimiento general de STARM en cualquiera de las formas de comunicación científica enumeradas anteriormente:\n\n"[Análisis y visualizaciones/Datos meteorológicos/Datos de cruceros/Datos de anclaje/Datos de Ferrybox] fueron proporcionados por la plataforma en línea STARM (https://www.starm.cl/) financiada, desarrollada y mantenida por la Agencia Nacional de Investigación y Desarrollo (ANID) a través del proyecto FONDAP-IDEAL, número 15150003 y la fundación Data Observatory. Conjunto de datos consultado [AAAA-MM-DD] en https://doi.org/¿????".\n\nPresentaciones de reuniones: si las visualizaciones de datos de STARM se utilizan en presentaciones públicas (en particular presentaciones de reuniones científicas), nos gustaría saberlo. Esta información es muy importante como justificación para el financiamiento, desarrollo y mantenimiento continuo de la plataforma. Agradeceríamos enormemente un breve mensaje de correo electrónico con el título de la presentación, el resumen (si está disponible) y la reunión donde se presentó. Envíe este mensaje a XXXXXX@uach.cl con “Información de presentación de STARM” como asunto.',
                                selectPlatforms: 'En la esquina superior derecha del mapapuede seleccionar diversas capas (GEBCO, OpenStreetMap u OpenStreetmap (gris)) para la visualización de diferentes mapas. También puede seleccionar cada una de las plataformas disponibles (Anclajes, Ferrybox, Estación Meteo, Cruceros) las que contienen un submenú donde puedeseleccionar diferentes fechas y ubicaciones. Una vez que seleccione la plataforma o la fecha, la selección se mostrará en el mapa.\n\nTambién puede seleccionar cada una de las plataformas haciendo clic en el mapa (codificado por colores, en la esquina inferior izquierda)',
                                selectStation: 'Los datos de CTD se muestran en color azul y azul oscuro. Cada estación está representada por puntos, donde se puede acceder a los datos de una estación individual haciendo clic en un punto específico.',
                                selectCruiser: 'Para mostrar los datos de todo el crucero, puede hacer clic en la línea que conecta los puntos de un crucero específico.',
                                downloadData: 'Para poder descargar los datos de una plataforma específica, se debe crear una cuenta e iniciar sesión.',
                                downloadGraphs: 'Para poder descargar un gráfico o figura, en la esquina superior derecha de esta, existe un menú contextual , haga clic y escoja el formato en que desea descargarla (png, jpeg, pdf o svg).',
                            }
                        },
                        contact: {
                            name: 'Nombre Completo',
                            email: 'Dirección de Correo Electrónico',
                            subject: 'Asunto',
                            message: 'Mensaje',
                            suffixError: " Incorrecto.",
                            success: "Mensaje enviado con éxito.",
                            error: "Lo sentimos, no pudimos enviar su mensaje. Por favor, inténtelo más tarde.",
                            sending: 'Enviando...',
                            send: 'Enviar',
                        },
                        welcome: {
                            title: '¡Le damos la bienvenida a STARM!',
                            body: 'Aquí tendrá acceso a los últimos datos registrados por el equipo de STARM:\nDatos meteorológicos tomados por ferryboxes, cruceros, estaciones meteoroloǵicas y anclajes en la Región de Magallanes y la Antártica Chilena.',
                            footer: 'STARM: "Series de Tiempo de datos Ambientales de la Región de Magallanes y la Antártica Chilena"',
                        },
                        notFound: {
                            title: '¡Oh no!',
                            message: 'No tenemos lo que busca, pero puede utilizar la navegación superior para volver al inicio.',
                        },
                    },

                    // Other needed for auto interpolation
                    logout: 'Cerrar Sesión',
                    footer: {
                        execute: 'Ejecutan',
                        develop: 'Desarrolla',
                        associate: 'Instituciones Asociadas',
                        followUs: 'Síguenos en nuestras redes sociales',
                    },
                }
            },

        }
    });

export default i18next;
