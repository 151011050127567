import { FC, useEffect, useMemo } from 'react';
import L from 'leaflet';
import 'leaflet.control.layers.tree';
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css';
import { useMap } from 'react-leaflet';
import { useTranslation } from 'react-i18next';

import { Platform } from '../api/platform';
import { showPlatforms } from '../helpers/domUtilities';
import '../views/details.css';
import useTreeFormatter from '../hooks/useTreeFormatter';

type MapLayersControlProps = {
    groupedSensors: Platform[][]
};

/**
 * Map's Base Layers
 */
const gebco = L.tileLayer.wms('https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?', { layers: 'GEBCO_LATEST', attribution: '&copy; <a href=&quot;https://www.gebco.net/disclaimer/&quot;>GEBCO</a>' });

const osm = L.tileLayer.wms(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors' });

const osm_grey = L.tileLayer.wms(
    'https://ows.terrestris.de/osm-gray/service?', { layers: 'OSM-WMS', attribution: '&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors' });



const MapLayersControl: FC<MapLayersControlProps> = ({groupedSensors}: MapLayersControlProps) => {
    
    const { t } = useTranslation();

    // Get context of main page.
    const map = useMap();
    const baseUrl = window.document.location.origin;


    // Get maps used as base layers
    const baseLayers: L.Control.Layers.TreeObject = useMemo(() =>({
        label: `${t('button.base')}`,
        children: [
            { label: '<span id="main-map">GEBCO</span>', layer: gebco},
            { label: 'OpenStreetMap', layer: osm },
            { label: `OpenStreetMap (${t('other.grey')})`, layer: osm_grey }
        ]
    }), [t]);


    // Get markers used for every layer
    
    const children = useTreeFormatter(groupedSensors, baseUrl);

    const markerLayers: L.Control.Layers.TreeObject = useMemo(() => ({
        label: `<strong id="sensors-selector">${t('special.platform_other')}</strong>`,
        selectAllCheckbox: 'Un/select all',
        collapsed: true,
        children,
        selectAll: true,
    }), [children, t]);
    

    // Creates Layers Control and Renders it
    const layersControl = useMemo(() => 
        new L.Control.Layers.Tree(baseLayers, markerLayers, {position: 'topright', spaceSymbol: '  '}
    ),[baseLayers, markerLayers]);
    
    useEffect(() => {
        // Add Layers to map and removes repeated controls
        layersControl.addTo(map);
        
        // Selects Gebco map as default
        const selectGebcoMap = document.getElementById('main-map')?.parentElement?.previousSibling as HTMLInputElement;
        selectGebcoMap.click();
        
        // Render platforms
        showPlatforms();

        // Prepends space on children selectors
        const childrenSelectors = document.getElementsByClassName('leaflet-control-layers-selector');
        const justChild = Object.values(childrenSelectors).filter(childSelector => !childSelector.classList.contains('leaflet-layerstree-sel-all-checkbox'));
        justChild.forEach(selector => selector.classList.add('space-before'));

        // Makes sure there's no duplicates rendered on the map
        return () => {
            // Remove markers to avoid duplicates and performance issues after translations.
            const parent = document.getElementsByClassName('leaflet-marker-pane')[0] as Node;
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }

            const polylines = document.getElementsByClassName('leaflet-interactive');
            if (polylines[0] && polylines[0].parentNode) {
                let polyParent = polylines[0].parentNode;
                while (polyParent.firstChild) {
                    polyParent.removeChild(polyParent.firstChild);
                }
            }
            
            layersControl.remove();
        };
    }, [layersControl, map])

    return null;
};

export default MapLayersControl