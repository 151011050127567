/**
 * Selects and shows every sensor on the main map on render.
 */
export const showPlatforms = (limit = 3, times = 0) => {
    // Only works on app's main page
    const path = window.location.pathname;
    
    if (times < limit && path.search(/sensor/gi) === -1) {
        try {
            const selectAllInput = document.getElementsByClassName('leaflet-layerstree-sel-all-checkbox')[0] as HTMLInputElement;
            selectAllInput.click();

            selectFerryboxLatest();

        }
        catch(error) {
            /* User might not be at Main Map */
            console.log(`No "Platforms" checkbox was found.${times < limit-1 ? ' Retrying...': ''}`);
            setTimeout(() => showPlatforms(limit, times+1), times*2000 + 2000);
        }
    }
}

/**
 * Selects and shows only the latest month of Ferrybox data.
 */
const selectFerryboxLatest = () => {
    const ferryboxNode = document.getElementsByClassName('leaflet-control-layers-overlays')[0].children[1].children[1];
    
    // Deselecting ferrybox
     const ferryboxSelector = ferryboxNode.children[0].children[1].children[0].children[0] as HTMLInputElement;

     ferryboxSelector.click();

    // Reselecting only latest data.

    // Finding year
    const latestYearIndex = ferryboxNode.children[1].children.length - 1;
    const latestFerryboxYear = ferryboxNode.children[1].children[latestYearIndex];

    // Finding month
    const latestMonthIndex = latestFerryboxYear.children[1].children.length-1;
    const latestFerryboxMonth = latestFerryboxYear.children[1].children[latestMonthIndex];

    // Selection
    const latestMonthSelect = latestFerryboxMonth.children[0].children[1].children[0].children[0] as HTMLInputElement;
    
    latestMonthSelect.click();    
}