import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { DateTime } from 'luxon';
import { MapContainer, LayersControl, WMSTileLayer, AttributionControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';

import MinimapMarker from './minimap-markers';
import { GraphApi, GraphFilters, GraphPoint, GraphProps, GraphRequest, GraphResponse } from '../api/graphs';
import { ErrorPlaceholder, LoadingPlaceholder } from './placeholders';
import MinimapLegend from './minimap-legend';
import '../views/details.css';

const Minimap: FC<GraphProps> = ({platform, sensors, y_axis, x_axis, filters, feature}: GraphProps) => {
    
    const { t } = useTranslation();

    const [showLegend, setShowLegend] = useState(false);

    // Update filters with proper datetime types
    const [ updatedFilters, setUpdatedFilters ] = useState(
        !filters ? {} : {
        ...filters,
        start_date: DateTime.fromJSDate(filters?.start_date).toISODate(),
        end_date: DateTime.fromJSDate(filters?.end_date).toISODate()
    });

    useEffect(() => {
        setUpdatedFilters(
            !filters ? {} : {
                ...filters,
                start_date: DateTime.fromJSDate(filters?.start_date).toISODate(),
                end_date: DateTime.fromJSDate(filters?.end_date).toISODate(),
            }
        );
    }, [filters]);


    // Get request data
    const [ request, setRequest ] = useState<GraphRequest>({
        platform,
        sensors,
        y_axis,
        x_axis,
        filters: updatedFilters as GraphFilters
    });

    useEffect(() => {
        setRequest({
            platform,
            sensors,
            y_axis,
            x_axis,
            filters: updatedFilters as GraphFilters
        });
    }, [platform, sensors, y_axis, x_axis, updatedFilters]);


    // Fetch Minimap Data
    const {
        data: graphResponse,
        error,
        mutate,
        isLoading
    } = useMutation<GraphResponse, Error, GraphRequest>(GraphApi.get, {mutationKey: 'minimapGraphData'})

    useEffect(() => {
        mutate(request);
    }, [mutate, request]);
    

    const graphPoints: GraphPoint[] = graphResponse?.data || [];
    
    const config = graphResponse?.metadata.config;

    if (error) return <ErrorPlaceholder />;

    else return (
        <section className="minimap mt-5 ps-xl-4">
            {
                isLoading ? 
                    <LoadingPlaceholder />
                    : 
                    <MapContainer
                        id='map'
                        maxBounds={[[-80, -170], [80, 170]]}
                        center={[-54, -70]}
                        zoom={7}
                        scrollWheelZoom={false}
                        minZoom={6}
                        maxZoom={12}
                        attributionControl={false}
                        className="overflow-hidden"
                        placeholder={<LoadingPlaceholder/>}
                        >
                        <AttributionControl position="bottomright" prefix={false} />
                        <LayersControl position="topright">

                            {/* MAP LAYERS */}
                            <LayersControl.BaseLayer  checked name="GEBCO">
                            <WMSTileLayer
                                attribution="<a href=&quot;https://www.gebco.net/disclaimer/&quot;>GEBCO</a>"
                                layers={'GEBCO_LATEST'}
                                url={`https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?`}
                            />
                            </LayersControl.BaseLayer >
                            <LayersControl.BaseLayer  name="OpenStreetMap">
                            <WMSTileLayer
                                attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            </LayersControl.BaseLayer >
                            <LayersControl.BaseLayer  name="OpenStreetMap Grey">
                            <WMSTileLayer
                                layers="OSM-WMS"
                                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                url="https://ows.terrestris.de/osm-gray/service?"
                            />
                            </LayersControl.BaseLayer >
                        </LayersControl>
                        {
                            Array.isArray(graphPoints) ?
                                graphPoints.map(point => (
                                    <MinimapMarker
                                        name={platform}
                                        sensor={
                                            Array.isArray(sensors) ? sensors[0] : sensors
                                        }
                                        location={point.location ? point.location : [[0,0]]}
                                        features={{feature: feature}}
                                        showPopup={false}
                                        color={point.colormap}
                                        key={`${point.datetime}-${point.location}`}
                                    />
                                ))
                            :
                                null
                        }
                        {
                            config?.colormap &&
                            (
                                showLegend ? 
                                <MinimapLegend config={config} feature={feature} setShowLegend={setShowLegend}/>
                                : <button id="colormap-btn" className="btn btn-light" onClick={() => setShowLegend(true)}>{t('button.colorMap')}</button>
                            )
                        }
                    </MapContainer>
            }
        </section>
    );
};

export default Minimap;
