/** 
 * Exports constant platform types.
 * **/

type Constant<Type> = {
    [key: string]: Type;
}

export const PLATFORM_TYPES: Constant<string> = {
    MOORINGS: 'moorings',
    FERRYBOX: 'ferrybox',
    STATIONS: 'stations',
    CRUISERS: 'cruisers',
};

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]