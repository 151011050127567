import { FC } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { showPlatforms } from "../helpers/domUtilities";
import logo from "../assets/starm.png";

const Header: FC = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    showPlatforms();
  };

  const itemsNav = [
    { label: t("nav.home"), to: "/" },
    { label: t("nav.sensors"), to: "/sensors" },
    { label: t("nav.about"), to: "/about" },
    { label: t("nav.center"), href: "http://www.centroideal.cl/" },
    { label: t("nav.faq"), to: "/faq" },
    { label: t("nav.contact"), to: "/contact" },
  ];

  return (
    <Navbar expand="lg" variant="dark" className="py-3 px-1 px-lg-3">
      <Container fluid>
        <Navbar.Brand >
          <img src={logo} className="logo" alt="STARM" />
        </Navbar.Brand>

        <div className="ms-1 ms-sm-2 ms-md-4 ms-lg-6 me-auto nav-name hide-on-xxs">
          <h6 className="mb-0 border-bottom text-white text-nowrap nav-title pb-1">
            {t("title.ideal")}
          </h6>
          <p className="mb-0 text-muted fs-small text-nowrap nav-subtitle">
            {t("title.developers")}
          </p>
        </div>

        <Navbar.Toggle />

        <Navbar.Collapse className="justify-content-end">
          <Nav className="mt-2 mt-lg-0">
            {itemsNav.map((item, index) => (
              <Nav.Item className="pe-2 px-xl-2" key={index}>
                {item.to ? (
                  <Nav.Link as={NavLink} to={item.to} className="text-nowrap">
                    {item.label}
                  </Nav.Link>
                ) : (
                  <a
                    href={item.href ? item.href : ""}
                    className="text-nowrap nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.label}
                  </a>
                )}
              </Nav.Item>
            ))}

            <div className="text-white d-flex justify-content-center ps-md-2 ps-xl-4 my-2">
              <span
                onClick={() => changeLanguage("es")}
                className={
                  "border-end pe-2 cursor-pointer " +
                  (i18n.resolvedLanguage === "es" ? "fw-bold" : "")
                }
              >
                {t("nav.langES")}
              </span>
              <span
                onClick={() => changeLanguage("en")}
                className={
                  "ps-2 cursor-pointer " +
                  (i18n.resolvedLanguage === "en" ? "fw-bold" : "")
                }
              >
                {t("nav.langEN")}
              </span>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
