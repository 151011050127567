

export const formatNumber = (number: number): string => {
    if (number > 999) return `${String(number).slice(0, -3)}k`;
    else if (number >= 0 && number < 10) return String(number.toFixed(2));
    else if (number < 0 && number >= -999) return String(number.toFixed(1));
    else if (number < 0 && number < -999) return `${String(number).slice(0, -3)}k`;
    else return String(number.toFixed(1));
};


export const formatLabel = (label: string) => {
    const arr = label.split(' ');
    if (arr.length === 1) return arr[0];
    else if (arr[0].length >= 10) return arr[0];
    else if (arr[1].length >= 10) return arr[0];
    else return `${arr[0]} ${arr[1]}`;
}

/**
 * Formats number to show only two significant decimals.
 * @params number to be formatted.
 */
export const significantFloat = (number: number): number => {
    if (!number) return number;
    const [ints, floats] = number.toString().split('.');
    if (!floats) return Number(ints);
    let significant = '';
    for (let i = 0, n = floats.length; i < n; i++) {
        if (floats[i] === '0') significant+=floats[i];
        else {
            significant+=floats[i] + ( floats[i+1] && floats[i+1] !== '0' ? floats[i+1] : '' );
            break;
        }
    }
    let result = ints+'.'+significant;
    return Number(result);
}

/**
 * Formats sensor name to Camel Case
 * @params raw sensor name
 */
export const formatName = (name: string | undefined): string => {
    if (!name) return '';
    let copy = name.replace(/-/ig, ' ').split(' ');
    const formated: string[] = [];
    copy.forEach(word => formated.push(word[0].toUpperCase()+word.slice(1)))
    return formated.join(' ');
};