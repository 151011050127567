import i18n from 'i18next';
import { useEffect, useState } from 'react';
import type { Marker } from 'leaflet';

import { Platform } from '../api/platform';
import { PLATFORM_TYPES, MONTHS } from '../constants';
import { formatCruisers } from '../helpers/cruisersHelpers';
import { getByYears, getByYearsMonths } from '../helpers/getSensorsBy';
import { createMarker } from '../components/custom-markers';


/**
 * Should return tree object as described in ClickUp Task #2h6hhcb
 */
const useTreeFormatter = (groupedSensors: Platform[][], baseUrl: string, lang = i18n.resolvedLanguage): L.Control.Layers.TreeObject[] => {
    
    const [ tree, setTree ] = useState<L.Control.Layers.TreeObject[]>([]);

    useEffect(() => {
        
        const stations = groupedSensors
            // Get only Stations.
            .filter(group => group[0].name === PLATFORM_TYPES.STATIONS)[0]
            // Create Layer Markers from every station.
            .map(marker => ({
                label: marker.sensor,
                layer: createMarker(baseUrl, true, marker),
            }));
        
    
    
        const moorings = groupedSensors
            // Get only Moorings.
            .filter(group => group[0].name === PLATFORM_TYPES.MOORINGS)[0]
            // Create Layer Markers from every mooring.
            .map(marker => ({
                label: marker.sensor,
                layer: createMarker(baseUrl, true, marker),
            }));
    
    
        // Get only Ferryboxes
        const ferryboxUnformatted = groupedSensors
            .filter(group => group[0].name === PLATFORM_TYPES.FERRYBOX)[0];
        
        // Get only every 5 points of data.
        /*
        const someFerryboxes: Platform[] = [];
        ferryboxUnformatted.forEach((ferrybox, index) => {if (index%10===0) someFerryboxes.push(ferrybox)})
        */

        // Get Ferrybox by years and months
        const ferryboxByYearMonth = getByYearsMonths(ferryboxUnformatted);
        
        
        const ferrybox = Object.entries(ferryboxByYearMonth).map(year => ({
            label: year[0],
            selectAllCheckbox: true,
            collapsed: true,
            children: Object.entries(year[1]).map(month => ({
                label: i18n.t(`months.${MONTHS[Number(month[0])]}`),
                selectAllCheckbox: true,
                collapsed: true,
                children: month[1].map(ferrybox => ({
                    label: ferrybox.sensor,
                    layer: createMarker(baseUrl, true, ferrybox),
                }))
            })),
        }));
        
        
        // Get only Cruisers
        const cruisersUnformatted = groupedSensors
        .filter(group => group[0].name === PLATFORM_TYPES.CRUISERS)[0];
    
        // Get Cruiser by years
        const cruiserByYears = getByYears(cruisersUnformatted);
    
        // Special Format Cruisers
        const formatedCruisers = formatCruisers(cruiserByYears);
    
        const cruisers = Object.entries(formatedCruisers).map(journey => ({
            label: journey[0],
            selectAllCheckbox: true,
            collapsed: true,
            children: [
                {
                    label: journey[1].route.sensor,
                    layer: createMarker(baseUrl, true, journey[1].route),
                },
                {
                    label: i18n.t('special.stations_gen'),
                    selectAllCheckbox: true,
                    collapsed: true,
                    children: journey[1].stops.map(stop => ({
                        label: stop.sensor,
                        layer: createMarker(baseUrl, true, stop),
                    }))
                }
            ]
        }))
    
        setTree([
            {
                label: i18n.t(`special.${PLATFORM_TYPES.MOORINGS}`),
                selectAllCheckbox: true,
                collapsed: true,
                children: moorings,
            },
            {
                label: i18n.t(`special.${PLATFORM_TYPES.FERRYBOX}`),
                selectAllCheckbox: true,
                collapsed: true,
                children: ferrybox,
            },
            {
                label: i18n.t('special.stations'),
                selectAllCheckbox: true,
                collapsed: true,
                children: stations,
            },
            {
                label: i18n.t(`special.${PLATFORM_TYPES.CRUISERS}`),
                selectAllCheckbox: true,
                collapsed: true,
                children: cruisers,
            },
        ])
        
        return () => {
            setTree([])
        };
    }, [groupedSensors, baseUrl, lang]);
    
    return tree;
};

export default useTreeFormatter;