import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../components/header';
import Footer from '../components/footer';

const Sensors: FC = () => {

    const { t } = useTranslation();

    return (
        <>
            <Header />
            <main className="py-5 container">
                <section className="row text-center">
                    <h1 className="text-accent fw-bold">{t('text.pageTitle.sensors')}</h1>
                </section>
                <section className="row my-4 py-4">
                    <div className="col my-4 mx-auto py-4 accordion" id="accordionSensors">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingMoorings">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMoorings" aria-expanded="false" aria-controls="collapseMoorings">
                                {t('special.moorings')}
                            </button>
                            </h2>
                            <div id="collapseMoorings"
                            className="accordion-collapse collapse" 
                            aria-labelledby="headingMoorings"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body p-0">
                                <ul className="list-group list-group-flush">
                                    <Link to={'mooring/mooring-beagle-channel'} className="list-group-item list-group-item-action py-4">Canal Beagle</Link>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingCruisers">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCruisers" aria-expanded="false" aria-controls="collapseCruisers">
                                {t('special.cruisers')}
                            </button>
                            </h2>
                            <div id="collapseCruisers"
                            className="accordion-collapse collapse" 
                            aria-labelledby="headingCruisers"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body p-0">
                                <ul className="list-group list-group-flush">
                                    <Link to={'cruisers/cruisers'} className="list-group-item list-group-item-action py-4"> {t('special.cruisers')} </Link>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingMetStations">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMetStations" aria-expanded="false" aria-controls="collapseMetStations">
                                {t('special.stations_lg')}
                            </button>
                            </h2>
                            <div id="collapseMetStations"
                            className="accordion-collapse collapse" 
                            aria-labelledby="headingMetStations" 
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body p-0">
                                <ul className="list-group list-group-flush">
                                    <Link to={'station/punta-santa-ana'} className="list-group-item list-group-item-action py-4">Punta Santa Ana</Link>
                                    <Link to={'station/canal-beagle'} className="list-group-item list-group-item-action py-4">Canal Beagle</Link>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFerryboxes">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFerryboxes" aria-expanded="false" aria-controls="collapseFerryboxes">
                            {t('special.ferrybox_other')}
                            </button>
                            </h2>
                            <div id="collapseFerryboxes"
                            className="accordion-collapse collapse" 
                            aria-labelledby="headingFerryboxes" 
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body p-0">
                                <ul className="list-group list-group-flush">
                                    <Link to={'ferrybox/yaghan'} className="list-group-item list-group-item-action py-4">Yaghan</Link>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>
    );
};

export default Sensors;
