import { FC, useState, useEffect, useCallback, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Form, Formik } from "formik";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import FiltersForm from "../components/filters-form";
import SensorFeature from "../components/sensor-feature";
import { DownloadSection, Option } from "../components/download-section";
import Minimap from "../components/minimap";
import {
	Platform,
	PlatformApi,
	PlatformFeature,
	PlatformResponse,
} from "../api/platform";
import { GraphFilters } from "../api/graphs";
import {
	LoadingPlaceholder,
	NoDataPlaceholder,
	ErrorPlaceholder,
} from "../components/placeholders";
import { getTimeRanges } from "../helpers/getSensorListData";
import Header from "../components/header";
import Footer from "../components/footer";
import "./details.css";

type Params = {
	formSensor: string;
	isList: boolean;
};

const Detail: FC = () => {
	const { t } = useTranslation();

	// State Set Up
	const [filters, setFilters] = useState<GraphFilters>();
	const [availableFeatures, setAvailableFeatures] = useState<Option[]>();
	const [availableAxes, setAvailableAxes] = useState<Option[]>();
	const [isList, setIsList] = useState(false);
	const navigate = useNavigate();

	// Get View's Data and fallback
	const {
		sensor,
		feature: selectedFeature,
		axis: selectedAxis,
	} = useParams();

	let arrSensor = sensor?.split(",") || [""];
	let relatedSensors = arrSensor.length > 1 ? arrSensor : arrSensor[0];

	// Used for filter form
	useEffect(() => {
		if (Array.isArray(relatedSensors)) setIsList(true);
		else setIsList(false);
	}, [relatedSensors]);

	let formSensor = Array.isArray(relatedSensors)
		? relatedSensors.filter((sensor) => !sensor.match(/route/gi))[0]
		: relatedSensors;

	const {
		data: platformResponse,
		error,
		isLoading: isFetching,
		mutate,
	} = useMutation<PlatformResponse, Error, Params>("platformData", () =>
		PlatformApi.get(formSensor!, isList)
	); // !
	// There is always a sensor at the URL when <Detail /> shows up.

	// Get data.
	useEffect(() => {
		mutate({ formSensor, isList } as Params);
	}, [mutate, formSensor, isList]);

	/**
	 * Gets the feature's data from a sensor using the feature's label.
	 */
	const getFeature = useCallback(
		(feature: string) => {
			if (
				!Array.isArray(platformResponse?.data) &&
				platformResponse?.data.features.hasOwnProperty(feature)
			) {
				return platformResponse?.data.features[feature];
			} else if (
				Array.isArray(platformResponse?.data) &&
				platformResponse?.data
					.filter((sensor) => sensor.sensor === formSensor)[0]
					.features.hasOwnProperty(feature)
			) {
				return platformResponse?.data.filter(
					(sensor) => sensor.sensor === formSensor
				)[0].features[feature];
			}
		},
		[platformResponse?.data, formSensor]
	);

	const timeRanges = useMemo(() => {
		if (platformResponse?.data) {
			const platformData = platformResponse?.data as Platform;
			return getTimeRanges(platformData);
		}
	}, [platformResponse?.data]);

	// Prepare data for filtering, plotting and downloading components.
	useEffect(() => {
		if (platformResponse?.data) {
			/**
			 * Transform data in case it comes as an array:
			 *    Get minimum and maximum dates, then use first cruiser to set up the rest of the filters parameters.
			 *    Then test. If necessary, change the cruiser's dates to match the retrieved ones.
			 */
			const data = Array.isArray(platformResponse?.data)
				? platformResponse?.data[0]
				: platformResponse?.data;

			// Now we know there is useful data
			if (!availableFeatures) {
				setAvailableFeatures(
					Object.entries<PlatformFeature>(data.features)
						.map(([value, { label }]) => ({ label: label, value }))
						.filter(
							({ label }) => label !== "Bottom depth at station"
						)
				);
			}

			// Makes sure to have a selected feature.
			if (!selectedFeature) {
				return navigate(
					`/sensor/${relatedSensors}/${
						Object.keys(data.features).filter(
							(key) => key !== "water-bottom-depth_m"
						)[0]
					}/by/${
						getFeature(Object.keys(data.features)[0])?.x_axis[0]
					}`
				);
			}

			const currentFeature = data.features[selectedFeature];

			// Makes sure to have axes available and a selected axis.
			if (!availableAxes) {
				setAvailableAxes(
					currentFeature.x_axis.map((item) => ({
						label: item,
						value: item,
					}))
				);
			}
			if (availableAxes && !selectedAxis) {
				return navigate(
					`/sensor/${relatedSensors}/${selectedFeature}/by/${availableAxes[0].label}`
				);
			}
		} else {
			if (!isFetching && formSensor) mutate({ formSensor, isList });
			if (error) console.error(error); // Must return error placeholder later on.
		}
	}, [
		platformResponse,
		error,
		isFetching,
		mutate,
		navigate,
		availableFeatures,
		availableAxes,
		selectedFeature,
		selectedAxis,
		relatedSensors,
		getFeature,
		formSensor,
		isList,
		t,
	]);

	// Change handlers
	const handleFeatureChange = (selected: Option | null) =>
		selected && selected.value !== selectedFeature
			? navigate(
					`/sensor/${relatedSensors}/${selected.value}/by/${selectedAxis}`
			  )
			: null;

	const handleAxisChange = (selected: Option | null) =>
		selected && selected.value !== selectedAxis
			? navigate(
					`/sensor/${relatedSensors}/${selectedFeature}/by/${selected.value}`
			  )
			: null;

	if (error) {
		return <ErrorPlaceholder />;
	}

	return (
		<>
			<Header />
			<div className='row d-flex justify-content-center mb-0 ms-lg-4'>
				<div className='col-10 ms-lg-4 p-4 pb-0'>
					<div className='row mb-2'>
						<div className='col-auto'>
							<Link
								to={"/"}
								className='nav-link link-dark fs-6 text-muted'
							>
								<FontAwesomeIcon
									icon={faChevronLeft}
									className='me-2'
								/>
								{t("button.return")}
							</Link>
						</div>
					</div>
					<div className='row'>
						{platformResponse?.data ? (
							<h1 className='border-bottom mb-0 p-1 fs-2'>
								{!Array.isArray(platformResponse?.data)
									? `${platformResponse?.data.sensor.replaceAll(
											/_+/gi,
											" "
									  )} - ${t(
											`special.${platformResponse?.data.name}`
									  )}`
									: `${platformResponse?.data[0].sensor.slice(
											0,
											platformResponse?.data[0].sensor.indexOf(
												"__"
											)
									  )} - ${t(
											`special.${platformResponse?.data[0].name}`
									  )}`}
							</h1>
						) : null}
					</div>
				</div>
			</div>
			{availableFeatures &&
			selectedFeature &&
			selectedAxis &&
			getFeature(selectedFeature) &&
			platformResponse?.data ? (
				<section className='row d-flex justify-content-center mx-4 my-5 detail-content'>
					<aside className='filters-section col-12 col-xl-4 p-0 p-md-4'>
						<div className='card mx-2 my-4'>
							<div className='card-header'>
								<h5>{t("title.feature")}</h5>
							</div>
							<Formik
								initialValues={{
									featuresSelect: availableFeatures.find(
										({ value }) => value === selectedFeature
									),
									axesSelect: availableAxes
										? availableAxes.find(
												({ value }) =>
													value === selectedAxis
										  )
										: null,
								}}
								onSubmit={() => {
									/* Not necessary as there isn't a submission button */
								}}
							>
								<Form className='card-body'>
									<section>
										<Select
											id='featuresSelect'
											name='featuresSelect'
											options={availableFeatures}
											isClearable={false}
											value={availableFeatures.find(
												({ value }) =>
													value === selectedFeature
											)}
											onChange={handleFeatureChange}
											formatOptionLabel={(option) =>
												t(`variables.${option.label}`)
											}
										/>
									</section>
									{availableAxes ? (
										<div className='mt-3'>
											<label className='form-label'>
												{t("graphComponent.by")}:
											</label>
											{availableAxes.length > 1 ? (
												<Select
													id='axesSelect'
													name='axesSelect'
													options={availableAxes}
													isClearable={false}
													value={availableAxes.find(
														({ value }) =>
															value ===
															selectedAxis
													)}
													onChange={handleAxisChange}
													formatOptionLabel={(
														option
													) =>
														t(
															`graphComponent.${option.label}`
														)
													}
												/>
											) : (
												<input
													type='text'
													placeholder={
														availableAxes[0]
															.value === "depth_m"
															? t(
																	"graphComponent.depth"
															  )
															: t(
																	"graphComponent.datetime"
															  )
													}
													className='form-control'
													disabled
												/>
											)}
										</div>
									) : (
										<NoDataPlaceholder />
									)}
								</Form>
							</Formik>
						</div>
						<div className='card mx-2 my-4'>
							<div className='card-header'>
								<h5>{t("title.filters")}</h5>
							</div>
							<div className='card-body'>
								<FiltersForm
									selectedFeature={selectedFeature}
									feature={getFeature(selectedFeature)!}
									x_axis={selectedAxis}
									setFilters={setFilters}
									timeRanges={timeRanges}
								/>
							</div>
						</div>
						<div className='card mx-2 my-4'>
							<div className='card-header'>
								<h5>{t("title.download")}</h5>
							</div>
							<div className='card-body'>
								<DownloadSection
									graphRequest={{
										platform: !Array.isArray(
											platformResponse.data
										)
											? platformResponse.data.name
											: platformResponse.data[0].name,
										sensors: Array.isArray(
											platformResponse.data
										)
											? relatedSensors!
											: platformResponse.data.sensor,
										y_axis: selectedFeature,
										x_axis: selectedAxis,
										filters: filters,
									}}
									availableYAxes={availableFeatures}
								/>
							</div>
						</div>
					</aside>
					<main className='col-12 col-xl-6 main-content mt-4 mt-xl-0 p-0 d-flex flex-column'>
						{isFetching ? (
							<LoadingPlaceholder />
						) : (
							<SensorFeature
								platform={
									!Array.isArray(platformResponse.data)
										? platformResponse.data.name
										: platformResponse.data[0].name
								}
								feature={getFeature(selectedFeature)!}
								y_axis={selectedFeature}
								x_axis={selectedAxis}
								sensors={relatedSensors!}
								filters={filters}
							/>
						)}

						{!Array.isArray(platformResponse.data) &&
						platformResponse.data.name === "ferrybox" ? (
							<Minimap
								platform={platformResponse.data.name}
								feature={getFeature(selectedFeature)!}
								y_axis={selectedFeature}
								x_axis={selectedAxis}
								sensors={platformResponse.data.sensor}
								filters={filters}
							/>
						) : null}
					</main>
				</section>
			) : isFetching ? (
				<LoadingPlaceholder />
			) : (
				<NoDataPlaceholder />
			)}

			<Footer />
		</>
	);
};

export default Detail;
