import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Feature } from 'geojson';
import i18n from 'i18next';

import { Platform, PlatformFeature } from '../api/platform';
import { PLATFORM_TYPES } from '../constants';

/**
 * Functions that creates custom leaflet Icons, PopUps and markers from sensor data.
 */

export const createIcon: (name: string) => L.DivIcon = (name) => {
  
    let icon: L.DivIcon;
    let iconOptions: L.DivIconOptions = {
        iconSize: [36, 49],
        popupAnchor: [12, -30],
    };

    switch(name) {
        case PLATFORM_TYPES.MOORINGS:
            iconOptions.iconAnchor = [20, 46];
            iconOptions.html = '<img src="../icons/mooringsIcon.svg" class="map-icon" style="width: 4rem;"></img>';
            icon = new L.DivIcon(iconOptions);
            break;
        case PLATFORM_TYPES.STATIONS:
            iconOptions.iconAnchor = [46, 50];
            iconOptions.html = '<img src="../icons/stationsIcon.svg" class="map-icon" style="width: 4rem;"></img>';
            icon = new L.DivIcon(iconOptions);
            break;
        case PLATFORM_TYPES.CRUISERS:
            iconOptions.iconAnchor = [15, 13];
            iconOptions.html = renderToStaticMarkup(<FontAwesomeIcon className='map-icon cruiser-icon' icon={faCircle} />);
            icon = new L.DivIcon(iconOptions);
            break;
        case PLATFORM_TYPES.FERRYBOX:
            iconOptions.iconAnchor = [15, 13];
            iconOptions.html = renderToStaticMarkup(<FontAwesomeIcon className='map-icon ferrybox-icon' icon={faCircle} />);
            icon = new L.DivIcon(iconOptions);
            break;
        default:
            iconOptions.iconAnchor = [14, 26];
            iconOptions.html = renderToStaticMarkup(<FontAwesomeIcon className='map-icon default-icon' icon={faMapMarkerAlt} />);
            icon = new L.DivIcon(iconOptions);
            break;
    }
    return icon;
};


export const createPopupContent = (baseUrl: string, {sensor, name, location, features, relatedSensors}: Platform): string => {
    let popupContent = `
        <h2 class="my-2">${sensor.replaceAll(/_+/gi, ' ')}</h2>
        <h5 class="my-0">${i18n.t('popup.belongs')}: <strong>${i18n.t('special.'+name)}</strong></h5>
        ${
            !Array.isArray(location) ? `<p class="lead text-muted my-0">Lat.: ${location.latitude.toFixed(2)}, Long.:${location.longitude.toFixed(2)}</p>` : ''
        }
        ${
            features && (
                `<h5>${i18n.t('popup.availableVars')}:</h5>
                <div class='list-group mb-0'>
                ${
                    Object.entries<PlatformFeature>(features)
                    .filter(([key, {label}]) => label !== 'Bottom depth at station')
                    .map(([key, {label, time}]) => (
                        `<a 
                            href="${baseUrl}/sensor/${relatedSensors || sensor}/${key}"
                            class="list-group-item list-group-item-action d-flex "
                            >
                            <strong>${i18n.t(`variables.${label || key}`)}</strong>
                            </a>
                            `
                            /**
                             * Next line may be used to check if sensors dates are correctly sorted.
                             */
                            //<p className="lead text-muted mt-0">[${time.start_date} - ${time.end_date}]</p>
                    )).join('')
                }
                </div>`
            )
        }

        <a href="${baseUrl}/sensor/${relatedSensors || sensor}" class="block btn btn-primary my-3 text-light">${i18n.t('button.open')}</a>
    `;
    return popupContent;
};


export const createMarker = (baseUrl: string, showPopup=true,{sensor, name, location, features, color, relatedSensors}: Platform) => {
    
    if (Array.isArray(location)) {
        if (PLATFORM_TYPES.FERRYBOX === name) {
            const reducedLoc = location.filter((loc, index) => index % 10 === 0);

            const geoJsonFeature: Feature = {
                type: 'Feature',
                properties: {
                    style: {
                        color: '#004070',
                        weight: 4,
                        opacity: 1
                    }
                },
                geometry: {
                    type: 'MultiPoint',
                    coordinates: reducedLoc.map(coords => [coords[1], coords[0]])
                }
            };
            const geoJson = new L.GeoJSON(geoJsonFeature, {
                pointToLayer: (geoJsonPoint, latlng) => L.marker(latlng, {
                    icon: createIcon(name)
                })
            });
            
            return !showPopup ? geoJson : geoJson.bindPopup(
                createPopupContent(baseUrl, {sensor, name, location, features}),
                { closeButton: false }
            );

        }
        else {
            const polyline = L.polyline(location, {
                weight: 6,
                color: color || 'red'
            });

            return !showPopup ? polyline : polyline.bindPopup(
                createPopupContent(baseUrl, {sensor, name, location, features, relatedSensors}),
                { closeButton: false }
            );
        }
    }
    else {
        const marker = L.marker([location.latitude, location.longitude], {
            icon: createIcon(name),
            riseOnHover: true,
            });

        return !showPopup ? marker : marker.bindPopup(
            createPopupContent(baseUrl, {sensor, name, location, features}),
            { closeButton: false }
        );
    }
};
