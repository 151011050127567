import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { ReactComponent as MooringsIcon } from "../assets/mooringsIcon.svg";
import { ReactComponent as StationsIcon } from "../assets/stationsIcon.svg";

/**
 * Main map legends
 */
const Legends: FC = () => {

    const { t } = useTranslation();

    const [ showLegend, setShowLegend ] = useState(false);

    return (
        <>
            {
                showLegend ? 
                <aside id="legends" className="card">
                    <section className="card-header d-flex">
                        <h3>{t('special.platform_other')}</h3>
                        <button id="close-legends" className="btn btn-light" onClick={() => setShowLegend(false)}>
                            <FontAwesomeIcon size={"1x"} icon={faTimes}/>
                        </button>
                    </section>
                    <section className="card-body px-4">
                        <h5 className="card-title d-flex justify-content-between">
                            <span>{t('special.moorings')}:</span>
                            <MooringsIcon style={{width: "2rem", height: "2rem", marginLeft: "20px"}} />
                        </h5>
                        <h5 className="card-title d-flex justify-content-between">
                            <span>{t('special.stations')}:</span>
                            <StationsIcon style={{width: "2rem", height: "2rem", marginLeft: "20px"}} />
                        </h5>
                        <h5 className="card-title d-flex justify-content-between">
                            <span>{t('special.ferrybox')}:</span>
                            <FontAwesomeIcon className="ferrybox-icon" style={{maxWidth: "1rem", marginRight: "8px", marginLeft: "20px"}} fixedWidth={true} size={"1x"} icon={faCircle}/>
                        </h5>
                        <h5 className="card-title d-flex justify-content-between">
                            <span>{t('special.cruisers')}:</span>
                            <FontAwesomeIcon className="cruiser-icon" style={{maxWidth: "1rem", marginRight: "8px", marginLeft: "20px"}} fixedWidth={true} size={"1x"} icon={faCircle}/>
                        </h5>
                    </section>
                </aside>
                :
                <button id="legend-btn" className="btn btn-light" onClick={() => setShowLegend(true)}>{t('special.platform_other')}</button>
            }
        </>
    );
};

export default Legends;