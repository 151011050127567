import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const WelcomeModal: FC = () => {

    const { t } = useTranslation();

    useEffect(() => {
        const newcomer = window.localStorage.getItem('starm_newcomer');
    
        if (newcomer === null) {
          // Show modal only for newcomers.
          setTimeout(() => {
            document.getElementById('modal-btn')?.click();
          }, 1000);
    
          // Add item to local storage.
          window.localStorage.setItem('starm_newcomer', 'false');
        }
    }, []);

    return (
        <>
            <button id="modal-btn" className="d-none" data-bs-toggle="modal" data-bs-target=".modal"></button>
            <div id="modal-box" className="modal fade" tabIndex={-1}>
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('text.welcome.title')}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label={t('button.close')}></button>
                </div>
                <div className="modal-body">
                    <p>{t('text.welcome.body')}</p>
                </div>
                <div className="modal-footer">
                    <small className="text-muted">{t('text.welcome.footer')}</small>
                </div>
                </div>
            </div>
            </div>
        </>
    );
};

export default WelcomeModal;